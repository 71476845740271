import React, { useState, useEffect, useMemo, useContext } from "react";
import { AuthContext } from "../Auth/auth";
import axios from "axios";
import {
  Accordion,
  Button,
  Row,
  Col,
  Card,
  Placeholder,
  Pagination,
  Spinner,
} from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";

import ScoreChart from "../Charts/scoreChart.js";
import PriceHistoryChart from "../Charts/priceHistoryChart.js";

import authHeader from "../../services/auth-header.js";
import CustomTable from "../Tables/Table.js";

import StatCard from "../Cards/statCard";
import { DateTime } from "luxon";
import { GiHealthNormal } from "react-icons/gi";
import { BiRefresh } from "react-icons/bi";
import { BsPencil } from "react-icons/bs";
import { BsPlusLg } from "react-icons/bs";
import { FaEthereum } from "react-icons/fa";

import EditStrategyPlayerComp from "../Actions/EditStrategyPlayerComp.js";

const PlayerInfo = (props) => {
  const { isAuthenticated, checkAuth, isLoading, role } =
    useContext(AuthContext);

  const { id, rarity } = useParams();
  const [player, setPlayer] = useState({});

  const [histoScores, setHistoScores] = useState([]);
  const [priceHistory, setPriceHistory] = useState([]);

  const [rowList, setRowList] = useState([]);
  const [priceStat, setPriceStat] = useState([]);

  const [scoreStats, setScoreStats] = useState([]);

  const [showStrategy, setShowStrategy] = useState(false);

  const [currentStrategy, setCurrentStrategy] = useState();

  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isDataRefreshing, setIsDataRefreshing] = useState(false);
  const [refresh, setRefresh] = useState([]);

  let navigate = useNavigate();

  useEffect(() => {
    // Retrieve player info
    setIsDataLoading(true);
    axios
      .post(
        "/sorare/getDBPlayerInfo/" + id,
        { rarity: rarity },
        { headers: authHeader() }
      )
      .then(({ data }) => {
        console.log(data);
        setPlayer(data);
        setIsDataLoading(false);
        setHistoScores(data.scores);
        setPriceHistory(data.priceHistory);

        setPriceStat(data.priceStat);

        setScoreStats(data.scoreStats);

        setCurrentStrategy(data.strategy);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [refresh]);

  const refreshPlayerStats = () => {
    setIsDataRefreshing(true);
    axios
      .post(
        "/sorare/refreshSinglePlayerPrice/" + id,
        { rarity: rarity },
        { headers: authHeader() }
      )
      .then((res) => {
        setIsDataRefreshing(false);
        if (res.status === 200) {
          setRefresh(refresh + 1);
        } else Promise.reject();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleShowStrategy = () => {
    setShowStrategy(true);
  };

  const printBuyStrategy = (strat) => {
    if (strat === "none") {
      return "Don't Buy";
    } else if (strat === "autoBid") {
      return "Buy @ Auto Bid";
    } else if (strat === "autoBidMM") {
      return "Buy @ Auto Bid MM";
    } else if (strat === "manualBid") {
      return "Buy @ Manual Bid";
    }
  };

  const printSellStrategy = (strat) => {
    if (strat === "none") {
      return "Don't Sell";
    } else if (strat === "classicHodl") {
      return "MT Hodl";
    } else if (strat === "strongHodl") {
      return "LT Hodl";
    } else if (strat === "sellAllAtPerc") {
      return "Sell All At Profit%";
    } else if (strat === "sell1AtPerc") {
      return "Sell 1 At Profit%";
    } else if (strat === "sellAllAtTarget") {
      return "Sell All At Target";
    } else if (strat === "sell1AtTarget") {
      return "Sell 1 At Target";
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Nb Month",
        disableFilters: true,
        accessor: "month",
      },
      {
        Header: "Nb Trans",
        disableFilters: true,
        accessor: "nbTrans",
      },
      {
        Header: "Min",
        disableFilters: true,
        accessor: "minPrice",
      },
      {
        Header: "Avg",
        disableFilters: true,
        accessor: "avgPrice",
      },
      {
        Header: "Avg5T",
        disableFilters: true,
        accessor: "avgPrice5Trans",
      },
      {
        Header: "Max",
        disableFilters: true,
        accessor: "maxPrice",
      },
      {
        Header: "Last Price",
        disableFilters: true,
        accessor: "lastPrice",
      },
      {
        Header: "LPQ",
        disableFilters: true,
        accessor: "lastPriceQuantile",
      },
      {
        Header: "Q10",
        disableFilters: true,
        accessor: "quantile10",
      },
      {
        Header: "Q20",
        disableFilters: true,
        accessor: "quantile20",
      },
      {
        Header: "Q80",
        disableFilters: true,
        accessor: "quantile80",
      },
      {
        Header: "Q90",
        disableFilters: true,
        accessor: "quantile90",
      },
      {
        Header: "Std Dev",
        disableFilters: true,
        accessor: "standardDev",
      },
      {
        Header: "Lvg Ratio",
        disableFilters: true,
        accessor: "lvgRatio",
      },
    ],
    []
  );

  const columnsScores = useMemo(
    () => [
      {
        Header: "Nb Games",
        disableFilters: true,
        accessor: "nbGames",
      },
      {
        Header: "Nb Apperances",
        disableFilters: true,
        accessor: "nbAppearance",
      },
      {
        Header: "Average Score",
        disableFilters: true,
        accessor: "avgScore",
      },
      {
        Header: "Standard Dev",
        disableFilters: true,
        accessor: "standardDev",
      },
    ],

    []
  );

  return isLoading ? (
    <Spinner animation="border" />
  ) : (
    <div>
      {isDataRefreshing ? (
        <Spinner animation="border" className="float-end" />
      ) : (
        <Button
          style={{ margin: 0 }}
          className="float-end"
          variant="outline-danger"
          disabled={isDataRefreshing}
          onClick={refreshPlayerStats}
        >
          <BiRefresh />
        </Button>
      )}
      <Row style={{ marginBottom: 10 }}>
        <Col xs={12} sm={6} md={4} className="d-flex justify-content-center">
          <Card
            style={{
              width: "auto",
              height: "auto",
              border: "none",
            }}
          >
            <Card.Img
              variant="top"
              src={player?.pictureUrl}
              className="photo"
            />
            <Card.ImgOverlay>
              {player?.injury?.length !== 0 && !isDataLoading ? (
                <span
                  style={{
                    zIndex: 999,
                    color: "red",
                    fontSize: 54,
                    alignItems: "left",
                  }}
                >
                  <GiHealthNormal />
                </span>
              ) : (
                ""
              )}
            </Card.ImgOverlay>
          </Card>
        </Col>
        <Col
          xs={12}
          sm={6}
          style={{ marginTop: 20, textAlign: "center", zIndex: 999 }}
        >
          {isDataLoading ? (
            <div>
              <h1>
                <Placeholder xs={8} />
              </h1>
              <p>
                <Placeholder xs={3} /> ‧ <Placeholder xs={3} />
                <br />
                <Placeholder xs={3} /> ‧ <Placeholder xs={3} />
                <br />
                <br />
                <Placeholder xs={4} as={Pagination} />
              </p>
            </div>
          ) : (
            <div>
              <h1>
                <b>{player?.displayName?.toUpperCase()}</b>
              </h1>

              <p>
                {player?.club?.domesticLeague?.name} ‧ {player?.club?.name}{" "}
                <img
                  src={player?.club?.pictureUrl}
                  alt=""
                  width="15"
                  height="15"
                  verticalAlign="text-top"
                ></img>
                <br />
                {player?.age} y.o. ‧ {player?.position}
              </p>
              {player?.injury?.length !== 0 ? (
                <div>
                  <p> 🤕: {player?.injury?.[0].kind} </p>{" "}
                  <p>
                    {" "}
                    {player?.injury?.[0].expectedEndDate
                      ? "Expected End: " +
                        DateTime.fromISO(
                          player?.injury?.[0].expectedEndDate
                        ).toLocaleString("fr-FR")
                      : ""}{" "}
                  </p>
                </div>
              ) : (
                ""
              )}

              <br />

              <Pagination className="flex justify-content-center">
                <Pagination.Item
                  size="sm"
                  key="limited"
                  active={rarity === "limited"}
                  href={"/player/" + player.id + "/limited"}
                  onClick={() => {
                    console.log("limited");
                  }}
                >
                  🟡
                </Pagination.Item>
                <Pagination.Item
                  size="sm"
                  key="rare"
                  active={rarity === "rare"}
                  href={"/player/" + player.id + "/rare"}
                  onClick={() => {
                    console.log("rare");
                  }}
                >
                  🔴
                </Pagination.Item>
                <Pagination.Item
                  size="sm"
                  key="super_rare"
                  active={rarity === "super_rare"}
                  href={"/player/" + player.id + "/super_rare"}
                  onClick={() => {
                    console.log("super rare");
                  }}
                >
                  🔵
                </Pagination.Item>
              </Pagination>
            </div>
          )}
        </Col>
      </Row>

      <Accordion defaultActiveKey={["1", "2", "3", "5"]} alwaysOpen>
        {role === "editor" ? (
          <Accordion.Item eventKey="1">
            <Accordion.Header>General Info</Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col sm={6} lg={3} style={{ zIndex: 900 }}>
                  <StatCard
                    header="Stats"
                    bodyMain={""}
                    bodySec={{
                      value:
                        "Average 5T: " + player?.priceStat?.[1]?.avgPrice5Trans,
                    }}
                    footer={"Floor: " + player?.floorPrice}
                    isDataLoading={isDataLoading}
                  ></StatCard>
                </Col>

                <Col sm={6} lg={3} style={{ zIndex: 900 }}>
                  <StatCard
                    header="# Cards"
                    bodyMain={{
                      value:
                        player?.cardCount?.["limited"] * 1 +
                        player?.cardCount?.["rare"] * 1,
                    }}
                    bodySec={""}
                    footer={
                      "🟡 " +
                      player?.cardCount?.["limited"] +
                      " 🔴 " +
                      player?.cardCount?.["rare"]
                    }
                    isDataLoading={isDataLoading}
                  ></StatCard>
                </Col>

                {player?.lockedProfitPlayer * 1 !== 0 ? (
                  <Col sm={6} lg={3} style={{ zIndex: 900 }}>
                    <StatCard
                      header="Total Locked: 
                  "
                      bodyMain={{
                        value: player?.lockedProfitPlayer,
                        unit: (
                          <FaEthereum style={{ verticalAlign: "text-top" }} />
                        ),
                      }}
                      bodySec={""}
                      footer={"Sold: " + player?.sellHisto?.length + " cards"}
                      isDataLoading={isDataLoading}
                    ></StatCard>
                  </Col>
                ) : (
                  ""
                )}
                {player?.cardCount?.["limited"] * 1 !== 0 ? (
                  <Col sm={6} lg={3} style={{ zIndex: 900 }}>
                    <StatCard
                      header="🟡 Cards PP: 
                  "
                      bodyMain={""}
                      bodySec={{
                        value:
                          "Min: " +
                          (
                            player?.limitedCards?.[0].purchasePrice /
                            10 ** 18
                          ).toFixed(4),
                      }}
                      footer={
                        "Max: " +
                        (
                          player?.limitedCards?.[
                            player?.limitedCards?.length - 1
                          ].purchasePrice /
                          10 ** 18
                        ).toFixed(4)
                      }
                      isDataLoading={isDataLoading}
                    ></StatCard>
                  </Col>
                ) : (
                  ""
                )}
                {player?.cardCount?.["rare"] * 1 !== 0 ? (
                  <Col sm={6} lg={3} style={{ zIndex: 900 }}>
                    <StatCard
                      header="🔴 Cards PP: 
                  "
                      bodyMain={""}
                      bodySec={{
                        value:
                          "Min: " +
                          (
                            player?.rareCards?.[0].purchasePrice /
                            10 ** 18
                          ).toFixed(4),
                      }}
                      footer={
                        "Max: " +
                        (
                          player?.rareCards?.[player?.rareCards?.length - 1]
                            .purchasePrice /
                          10 ** 18
                        ).toFixed(4)
                      }
                      isDataLoading={isDataLoading}
                    ></StatCard>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        ) : (
          ""
        )}
        {role == "editor" ? (
          currentStrategy ? (
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                {" "}
                Strategy : {rarity}{" "}
                <Button
                  style={{ marginLeft: 5 }}
                  size="sm"
                  variant="outline-warning"
                  onClick={() => {
                    handleShowStrategy();
                  }}
                >
                  <BsPencil style={{ color: "orange", fontSize: "15px" }} />
                </Button>
              </Accordion.Header>
              <Accordion.Body>
                <Row>
                  <div>
                    <Row>
                      <Col sm={6} lg={3} style={{ zIndex: 900 }}>
                        <StatCard
                          header="Buy Strategy
                  "
                          bodyMain={{
                            value: printBuyStrategy(
                              currentStrategy?.buyStrategy
                            ),
                          }}
                          bodySec={{ value: "" }}
                          footer={""}
                          isDataLoading={isDataLoading}
                        ></StatCard>
                      </Col>
                      {currentStrategy.buyStrategy === "autoBid" ? (
                        <Col style={{ zIndex: 900 }}>
                          <StatCard
                            header="AutoBid
                  "
                            footer={""}
                            bodyMain={{
                              value: currentStrategy?.autoBid,
                              unit: (
                                <FaEthereum
                                  style={{ verticalAlign: "text-top" }}
                                />
                              ),
                            }}
                            bodySec={{
                              value: "",
                            }}
                            isDataLoading={isDataLoading}
                          ></StatCard>
                        </Col>
                      ) : currentStrategy.buyStrategy === "autoBidMM" ? (
                        <Col lg={3} sm={6} style={{ zIndex: 900 }}>
                          <StatCard
                            header="AutoBid MM
                  "
                            footer={""}
                            bodyMain={{
                              value: currentStrategy?.autoBidMM,
                              unit: (
                                <FaEthereum
                                  style={{ verticalAlign: "text-top" }}
                                />
                              ),
                            }}
                            bodySec={{
                              value: "",
                            }}
                            isDataLoading={isDataLoading}
                          ></StatCard>
                        </Col>
                      ) : currentStrategy.buyStrategy === "manualBid" ? (
                        <Col lg={3} sm={6} style={{ zIndex: 900 }}>
                          <StatCard
                            header="Manual Bid
                  "
                            footer={""}
                            bodyMain={{
                              value: currentStrategy?.manualBid,
                              unit: (
                                <FaEthereum
                                  style={{ verticalAlign: "text-top" }}
                                />
                              ),
                            }}
                            bodySec={{
                              value: "",
                            }}
                            isDataLoading={isDataLoading}
                          ></StatCard>
                        </Col>
                      ) : (
                        ""
                      )}
                      <Col lg={3} sm={6} style={{ zIndex: 900 }}>
                        <StatCard
                          header="Budget
                  "
                          footer={""}
                          bodyMain={{
                            value: currentStrategy?.budget,
                            unit: (
                              <FaEthereum
                                style={{ verticalAlign: "text-top" }}
                              />
                            ),
                          }}
                          bodySec={{
                            value: "Used: " + currentStrategy?.spentOnPlayer,
                          }}
                          isDataLoading={isDataLoading}
                        ></StatCard>
                      </Col>
                      <Col lg={3} sm={6} style={{ zIndex: 900 }}>
                        <StatCard
                          header="Safety Bid
                  "
                          footer={""}
                          bodyMain={{
                            value: currentStrategy?.safetyBid,
                            unit: (
                              <FaEthereum
                                style={{ verticalAlign: "text-top" }}
                              />
                            ),
                          }}
                          bodySec={{
                            value: "",
                          }}
                          isDataLoading={isDataLoading}
                        ></StatCard>
                      </Col>
                    </Row>
                    <Row>
                      <p></p>
                      <hr />
                    </Row>
                    <Row>
                      <Col lg={3} sm={6} style={{ zIndex: 900 }}>
                        <StatCard
                          header="Sell Strategy
                  "
                          bodyMain={{
                            value: printSellStrategy(
                              currentStrategy?.sellStrategy
                            ),
                          }}
                          bodySec={{ value: "" }}
                          footer={""}
                          isDataLoading={isDataLoading}
                        ></StatCard>
                      </Col>
                      {currentStrategy.sellStrategy === "sellAllAtPerc" ||
                      currentStrategy.sellStrategy === "sell1AtPerc" ? (
                        <Col lg={3} sm={6} style={{ zIndex: 900 }}>
                          <StatCard
                            header="Profit %
                  "
                            bodyMain={{
                              value:
                                (
                                  (currentStrategy?.sellAtPerc - 1) *
                                  100
                                ).toFixed(0) + "%",
                            }}
                            bodySec={{ value: "" }}
                            footer={""}
                            isDataLoading={isDataLoading}
                          ></StatCard>
                        </Col>
                      ) : currentStrategy?.sellStrategy === "sell1AtTarget" ||
                        currentStrategy?.sellStrategy === "sellAllAtTarget" ? (
                        <Col lg={3} sm={6} style={{ zIndex: 900 }}>
                          <StatCard
                            header="Target
                  "
                            bodyMain={{
                              value: currentStrategy?.targetSell,
                              unit: (
                                <FaEthereum
                                  style={{ verticalAlign: "text-top" }}
                                />
                              ),
                            }}
                            bodySec={{ value: "" }}
                            footer={""}
                            isDataLoading={isDataLoading}
                          ></StatCard>
                        </Col>
                      ) : currentStrategy?.sellStrategy === "classicHodl" ||
                        currentStrategy?.sellStrategy === "strongHodl" ? (
                        <Col lg={3} sm={6} style={{ zIndex: 900 }}>
                          <StatCard
                            header="Next Sell @ Profit 
                  "
                            bodyMain={{
                              value:
                                (
                                  (currentStrategy?.stratPerc?.coef - 1) *
                                  100
                                ).toFixed(0) + "%",
                            }}
                            bodySec={{
                              value:
                                "Expected Nb: " +
                                currentStrategy?.stratPerc?.expectedNB,
                            }}
                            footer={""}
                            isDataLoading={isDataLoading}
                          ></StatCard>
                        </Col>
                      ) : (
                        ""
                      )}
                    </Row>
                  </div>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          ) : (
            <Accordion.Item>
              <Accordion.Header>
                {" "}
                Strategy:
                <Button
                  style={{ marginLeft: 5, marginTop: 0, marginBottom: 0 }}
                  size="sm"
                  variant="outline-success"
                  onClick={() => {
                    handleShowStrategy();
                  }}
                >
                  <BsPlusLg
                    style={{
                      color: "green",
                      fontSize: "15px",
                      verticalAlign: "text-top",
                    }}
                  ></BsPlusLg>{" "}
                  {rarity == "limited" ? "🟡" : rarity == "rare" ? "🔴" : "🔵"}
                </Button>
              </Accordion.Header>
            </Accordion.Item>
          )
        ) : (
          ""
        )}

        <Accordion.Item eventKey="3">
          <Accordion.Header>Price Chart</Accordion.Header>
          <Accordion.Body style={{ padding: 5 }}>
            <PriceHistoryChart
              priceHistory={priceHistory}
              player={player}
            ></PriceHistoryChart>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>Price Stat Table</Accordion.Header>
          <Accordion.Body>
            <Row>
              {
                <CustomTable
                  columns={columns}
                  data={priceStat}
                  setRowList={setRowList}
                  hiddenCols={[]}
                  hideGlobalFilter={true}
                />
              }
            </Row>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>Score Chart</Accordion.Header>
          <Accordion.Body style={{ padding: 5 }}>
            <ScoreChart pl={histoScores}></ScoreChart>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="6">
          <Accordion.Header>Score Stat Table</Accordion.Header>
          <Accordion.Body>
            <Row>
              {
                <CustomTable
                  columns={columnsScores}
                  data={scoreStats}
                  setRowList={setRowList}
                  hiddenCols={[]}
                  hideGlobalFilter={true}
                />
              }
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <EditStrategyPlayerComp
        currentStrategy={{
          ...currentStrategy,
          playerId: id,
          displayName: player?.displayName,
          rarity: rarity,
        }}
        setCurrentStrategy={setCurrentStrategy}
        showStrategy={showStrategy}
        setShowStrategy={setShowStrategy}
        isPlayerComponent={true}
      />
    </div>
  );
};

export default PlayerInfo;
