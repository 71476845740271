import {
  Card,
  Placeholder,
  OverlayTrigger,
  Tooltip,
  Button,
} from "react-bootstrap";
import { BsFillQuestionCircleFill } from "react-icons/bs";

const StatCard = (props) => {
  return (
    <Card
      style={{
        borderRadius: "15px",
        height: "auto",
        width: "auto",
        ...props.style,
      }}
    >
      <Card.Body
        style={{
          marginTop: 0,
          paddingTop: 5,
          paddingBottom: 5,
          paddingLeft: 10,
          paddingRight: 10,
        }}
      >
        <Card.Title style={{ color: "darkgray" }}>
          <b>{props.header}</b>
          {props.help ? (
            <>
              <OverlayTrigger
                key={"right"}
                placement={"right"}
                overlay={<Tooltip id={"tooltip-right"}>{props.help}</Tooltip>}
              >
                <Button
                  size="sm"
                  style={{
                    backgroundColor: "white",
                    border: "white",
                    paddingLeft: 5,
                    paddingBottom: 0,
                    paddingTop: 0,
                    margin: 0,
                  }}
                >
                  <BsFillQuestionCircleFill
                    style={{ color: "lightGrey", verticalAlign: "text-top" }}
                  />
                </Button>
              </OverlayTrigger>
            </>
          ) : (
            ""
          )}
        </Card.Title>
        {props.isDataLoading ? (
          <Placeholder as={Card.Text} animation="glow">
            <Placeholder xs={8} />
            <Placeholder xs={6} />
          </Placeholder>
        ) : (
          <Card.Text>
            {props.mainColor
              ? props.bodyMain.value > 0
                ? [
                    <span style={{ fontSize: 14, color: "green" }}>
                      {props.bodyMain.unit}
                    </span>,
                    <span style={{ fontSize: 20, color: "green" }}>
                      <b>{props.bodyMain.value}</b>
                    </span>,
                  ]
                : [
                    <span style={{ fontSize: 14, color: "red" }}>
                      {props.bodyMain.unit}
                    </span>,
                    <span style={{ fontSize: 20, color: "red" }}>
                      <b>{props.bodyMain.value}</b>
                    </span>,
                  ]
              : [
                  <span style={{ fontSize: 14 }}>{props.bodyMain.unit}</span>,
                  <span style={{ fontSize: 20 }}>
                    <b>{props.bodyMain.value}</b>
                  </span>,
                ]}
            {props.secColor
              ? parseFloat(props.bodySec) > 0
                ? [
                    <span style={{ fontSize: 10, color: "green" }}>
                      {props.bodySec.unit}
                    </span>,
                    <span style={{ fontSize: 14, color: "green" }}>
                      {" "}
                      {props.bodySec.value}
                    </span>,
                  ]
                : [
                    <span style={{ fontSize: 10, color: "red" }}>
                      {props.bodySec.unit}
                    </span>,
                    <span style={{ fontSize: 14, color: "red" }}>
                      {" "}
                      {props.bodySec.value}
                    </span>,
                  ]
              : [
                  <span style={{ fontSize: 10, color: "grey" }}>
                    {props.bodySec.unit}
                  </span>,
                  <span style={{ fontSize: 14, color: "grey" }}>
                    {" "}
                    {props.bodySec.value}
                  </span>,
                ]}
            <br />
            <span style={{ fontSize: 14, color: "grey" }}>{props.footer}</span>
          </Card.Text>
        )}
      </Card.Body>
    </Card>
  );
};

export default StatCard;
