import React from "react";
import { DateTime } from "luxon";

import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  AreaChart,
  Area,
  Brush,
  ResponsiveContainer,
} from "recharts";

const formatXAxis = (tickFormat) => {
  return DateTime.fromMillis(tickFormat).format("DD/MM/YYYY");
};

const BS40ProfitChart = (props) => {
  let data = props.userGraph;
  console.log(data);
  const renderLineChart = (
    <ResponsiveContainer width="100%" height={350}>
      <AreaChart
        data={data}
        fontSize={12}
        margin={{
          top: 5,
          right: 0,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          tickFormatter={(date) => DateTime.fromISO(date).toFormat("MM/yyyy")}
        />
        <YAxis
          width={20}
          angle={-50}
          domain={[
            (dataMin) => (dataMin * 1.1).toFixed(1) * 1,
            (dataMax) => (dataMax * 1.1).toFixed(1) * 1,
          ]}
        />

        <Tooltip
          labelFormatter={(date) =>
            DateTime.fromISO(date).toFormat("dd/MM/yyyy")
          }
        />

        <Area
          type="monotone"
          name="BS Profit"
          stackId="1"
          dataKey="bsProfTotal"
          stroke="blue"
          fill="none"
        />
        <Area
          type="monotone"
          name="Portfolio Profit"
          stackId="3"
          dataKey="evol"
          stroke="green"
          fill="none"
        />
        <Brush
          dataKey="date"
          height={30}
          stroke="#8884d8"
          tickFormatter={(date) =>
            DateTime.fromISO(date).toFormat("dd/MM/yyyy")
          }
        />
      </AreaChart>
    </ResponsiveContainer>
  );

  return renderLineChart;
};

export default BS40ProfitChart;
