// SearchPlayer Component for update street data

// Import Modules
import React, { useState, useMemo, useEffect, useContext } from "react";
import { AuthContext } from "../Auth/auth";
import { Link, useNavigate } from "react-router-dom";
import { BsCheck } from "react-icons/bs";
import {
  Container,
  Col,
  Row,
  Dropdown,
  DropdownButton,
  Button,
  Modal,
  Form,
  Spinner,
  Accordion,
} from "react-bootstrap";
import axios from "axios";

import CustomTable from "../Tables/Table";

import authHeader from "../../services/auth-header";

import Select from "react-select";

// searchPlayer Component
const SearchPlayer = (props) => {
  const { isAuthenticated, checkAuth, isLoading, role } =
    useContext(AuthContext);
  let navigate = useNavigate();
  const [players, setPlayers] = useState([]);

  const [showTable, setShowTable] = useState(false);

  //Edit strategy
  const [refresh, setRefresh] = useState("");
  const [editRow, setEditRow] = useState([]);
  const [rowList, setRowList] = useState({});
  const [showStrategy, setShowStrategy] = useState(false);

  const [playerSlug, setPlayerSlug] = useState([]);
  const [currentStrategy, setCurrentStrategy] = useState({});
  const [safetyBid, setSafetyBid] = useState("");
  const [manualBid, setManualBid] = useState("");
  const [budget, setBudget] = useState("");
  const [sellAtPerc, setSellAtPerc] = useState("default");
  const [buyStrategy, setBuyStrategy] = useState("default");
  const [sellStrategy, setSellStrategy] = useState("default");
  const [rarity, setRarity] = useState("limited");

  const [clubList, setClubList] = useState([]);
  const [selectClub, setSelectClub] = useState();
  const [playerName, setPlayerName] = useState("");

  useEffect(() => {
    axios
      .get("/sorare/getClubList", { headers: authHeader() })
      .then(({ data }) => {
        console.log(data);
        setClubList(data.clubList);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // onSubmit handler
  const onSubmit = (event) => {
    event.preventDefault();
    axios
      .post(
        "/sorare/search/",
        {
          queryName: playerName,
          club: selectClub?.id,
        },
        {
          headers: authHeader(),
        }
      )
      .then(({ data }) => {
        console.log(data);
        setShowTable(true);
        setPlayers(data);
      });
  };

  const columns = useMemo(
    () => [
      {
        // first group - Player info
        Header: "Player",

        columns: [
          {
            Header: "Name",
            accessor: "displayName",
            Cell: (props) => {
              return (
                <Link to={"/player/" + props.row.original.id + "/" + rarity}>
                  {props.value}
                </Link>
              );
            },
          },
          {
            Header: "Club",
            accessor: "Club.name",
          },
        ],
      },
      role === "editor"
        ? {
            Header: "Strategy",

            columns: [
              {
                Header: "Edit",
                accessor: "Strategies",
                disableFilters: true,
                Cell: (props) => {
                  if (props.value.length !== 0) {
                    for (let strategy of props.value) {
                      if (strategy.rarity == rarity) {
                        return (
                          <div>
                            <Button
                              size="sm"
                              variant="outline-warning"
                              onClick={() => {
                                handleShowStrategy();
                                setEditRow(props.row.original);
                                setCurrentStrategy(strategy);
                              }}
                            >
                              {" "}
                              Edit {rarity} strat
                            </Button>
                          </div>
                        );
                      } else {
                        return (
                          <div>
                            <Button
                              size="sm"
                              variant="outline-success"
                              onClick={() => {
                                handleShowStrategy();
                                setEditRow(props.row.original);
                              }}
                            >
                              Add {rarity} strat
                            </Button>
                          </div>
                        );
                      }
                    }
                  } else {
                    return (
                      <div>
                        <Button
                          size="sm"
                          variant="outline-success"
                          onClick={() => {
                            handleShowStrategy();
                            setEditRow(props.row.original);
                          }}
                        >
                          Add {rarity} strat
                        </Button>
                      </div>
                    );
                  }
                },
              },
              {
                Header: "Status",
                accessor: "published",
                disableFilters: true,
                Cell: (props) => {
                  if (props.value == "Pending") {
                    return <Spinner animation="border" />;
                  } else if (props.value == "Published") {
                    return (
                      <BsCheck style={{ color: "green", fontSize: "35px" }} />
                    );
                  } else {
                    return "";
                  }
                },
              },
            ],
          }
        : { Header: " ", columns: [] },
    ],
    [refresh, players, rarity]
  );

  const handleCloseStrategy = () => {
    setCurrentStrategy({});
    setBuyStrategy("default");
    setSellStrategy("default");
    setBudget("");
    setManualBid("");
    setSafetyBid("");
    setSellAtPerc("default");
    setShowStrategy(false);
  };
  const handleShowStrategy = () => {
    setShowStrategy(true);
  };
  const handleSaveStrategy = (event) => {
    event.preventDefault();
    setShowStrategy(false);
    let index = players.findIndex((element) => element.id == editRow.id);
    let newPlayers = [...players];

    let newStrategy = {};

    if (buyStrategy !== "default") {
      newStrategy.buyStrategy = buyStrategy;
      newPlayers[index].published = "Pending";
    }
    if (sellStrategy !== "default") {
      newStrategy.sellStrategy = sellStrategy;
      newPlayers[index].published = "Pending";
    }
    if (budget !== "default") {
      newStrategy.budget = budget;
      newPlayers[index].published = "Pending";
    }
    if (safetyBid !== "default") {
      newStrategy.safetyBid = safetyBid;
      newPlayers[index].published = "Pending";
    }
    if (manualBid !== "default") {
      newStrategy.manualBid = manualBid;
      newPlayers[index].published = "Pending";
    }
    if (sellAtPerc !== "default") {
      newStrategy.sellAtPerc = sellAtPerc;
    }

    newPlayers[index].Strategies.push(newStrategy);
    console.log(newStrategy);
    axios
      .post(
        "/sorare/editPlayerStrategy/",
        {
          playerId: editRow.id,
          rarity: rarity,
          buyStrategy: newStrategy?.buyStrategy,
          sellStrategy: newStrategy?.sellStrategy,
          sellAtPerc: newStrategy?.sellAtPerc,
          safetyBid: newStrategy?.safetyBid,
          manualBid: newStrategy?.manualBid,
          budget: newStrategy?.budget,
        },
        { headers: authHeader() }
      )
      .then(({ data }) => {
        console.log(data);
        if (data.updated) {
          newPlayers[index].published = "Published";
          setRefresh(refresh + 1);
          setPlayers(newPlayers);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    setBuyStrategy("default");
    setSellStrategy("default");
    setBudget("");
    setManualBid("");
    setSafetyBid("");
    setSellAtPerc("default");
    setCurrentStrategy({});
  };

  const handleSelectRarity = (e) => {
    setRarity(e);
  };

  const handleAddPlayer = (event) => {
    event.preventDefault();

    axios
      .post(
        "/sorare/addPlayerBySlug/",
        {
          slug: playerSlug,
        },
        { headers: authHeader() }
      )
      .then(({ data }) => {
        console.log(data);

        navigate("/player/" + data?.player?.id + "/" + rarity);
        //navigate(0);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onchangeSelectClub = (club) => {
    setSelectClub(club);
  };

  const validateSearch = () => {
    if (playerName == "" && !selectClub) {
      return false;
    } else return true;
  };

  // Return Player form
  return isLoading ? (
    <Spinner animation="border" />
  ) : (
    <div>
      <Accordion defaultActiveKey={["0"]} alwaysOpen>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Search Player in BDD</Accordion.Header>
          <Accordion.Body>
            {" "}
            <Row>
              <Col md={12} lg={10}>
                <Form onSubmit={onSubmit}>
                  <Row>
                    <Col md={6} lg={5}>
                      <Form.Group>
                        <Form.Control
                          type="text"
                          placeholder="Search Player"
                          onChange={(e) => setPlayerName(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6} lg={5}>
                      <Select
                        onChange={onchangeSelectClub}
                        isClearable
                        placeholder="Optional: Select Club"
                        getOptionValue={(option) => option.id}
                        getOptionLabel={(option) => option.name}
                        options={clubList}
                        styles={{
                          menu: (styles) => ({ ...styles, zIndex: 999 }),
                        }}
                      />
                    </Col>
                    <Col md={3} lg={2}>
                      <Button
                        variant="outline-success"
                        type="submit"
                        style={{ marginBottom: 5 }}
                        disabled={!validateSearch()}
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
              <Col md={12} lg={2}>
                <DropdownButton
                  variant="outline-primary"
                  onSelect={handleSelectRarity}
                  title={"Rarity : " + rarity}
                  id="sortByScroll"
                >
                  <Dropdown.Item eventKey={"limited"}>Limited</Dropdown.Item>
                  <Dropdown.Item eventKey={"rare"}> Rare </Dropdown.Item>
                  <Dropdown.Item eventKey={"super_rare"}>
                    {" "}
                    Super Rare{" "}
                  </Dropdown.Item>
                </DropdownButton>
              </Col>
            </Row>
            <Row>
              {showTable ? (
                <CustomTable
                  columns={columns}
                  data={players}
                  hideFilter={true}
                  setRowList={setRowList}
                  hiddenCols={[]}
                />
              ) : null}
            </Row>
            <Modal
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={showStrategy}
              onHide={handleCloseStrategy}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  {editRow.displayName} - {rarity}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form onSubmit={handleSaveStrategy}>
                  <Form.Group className="mb-3">
                    <Form.Label>Buy Strategy</Form.Label>
                    <Form.Select
                      defaultValue={
                        currentStrategy.buyStrategy
                          ? currentStrategy.buyStrategy
                          : "default"
                      }
                      onChange={(e) => setBuyStrategy(e.target.value)}
                    >
                      <option value="default"></option>
                      <option value="autoBid">Buy @ Auto Bid</option>
                      <option value="autoBidMM">Buy @ Auto Bid MM </option>
                      <option value="manualBid">Buy @ Manual Bid</option>
                      <option value="none"> Do not buy </option>
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasic">
                    <Form.Label> Budget </Form.Label>
                    <Form.Control
                      type="number"
                      step="0.0001"
                      min="0"
                      placeholder="Set Budget"
                      defaultValue={(currentStrategy.budget / 10 ** 18).toFixed(
                        4
                      )}
                      onChange={(e) => setBudget(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasic">
                    <Form.Label> Safety Bid</Form.Label>
                    <Form.Control
                      type="number"
                      step="0.0001"
                      min="0"
                      placeholder="Set Safety Bid"
                      defaultValue={(
                        currentStrategy.safetyBid /
                        10 ** 18
                      ).toFixed(4)}
                      onChange={(e) => setSafetyBid(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasic">
                    <Form.Label> Manual Bid</Form.Label>
                    <Form.Control
                      type="number"
                      step="0.0001"
                      min="0"
                      placeholder="Set Manual Bid "
                      defaultValue={(
                        currentStrategy.manualBid /
                        10 ** 18
                      ).toFixed(4)}
                      onChange={(e) => setManualBid(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Sell Strategy</Form.Label>
                    <Form.Select
                      defaultValue={
                        currentStrategy.sellStrategy
                          ? currentStrategy.sellStrategy
                          : "default"
                      }
                      onChange={(e) => setSellStrategy(e.target.value)}
                    >
                      <option value="default"></option>
                      <option value="classicHodl">MT Hodl</option>
                      <option value="strongHodl">LT Hodl</option>
                      <option value="sellAllAtPerc">Sell All @ Profit%</option>
                      <option value="sell1AtPerc">Sell 1 @ Profit%</option>
                      <option value="sellAllAtTarget">Sell All @ Target</option>
                      <option value="sell1AtTarget">Sell 1 @ Target</option>
                      <option value="none"> None</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Sell At Percentage</Form.Label>
                    <Form.Select
                      defaultValue={
                        currentStrategy?.sellAtPerc
                          ? currentStrategy?.sellAtPerc
                          : "default"
                      }
                      onChange={(e) => setSellAtPerc(e.target.value)}
                    >
                      <option value="default"></option>
                      <option value="1">0%</option>
                      <option value="1.1">10%</option>
                      <option value="1.2">20%</option>
                      <option value="1.3">30%</option>
                      <option value="1.4"> 40%</option>
                      <option value="1.75"> 75%</option>
                      <option value="2"> 100%</option>
                      <option value="3"> 200%</option>
                    </Form.Select>
                  </Form.Group>
                  <Button
                    variant="outline-success"
                    type="submit"
                    //disabled={!validateForm()}
                  >
                    Submit
                  </Button>
                </Form>
              </Modal.Body>
            </Modal>
          </Accordion.Body>
        </Accordion.Item>
        {role === "editor" ? (
          <Accordion.Item eventKey="1">
            <Accordion.Header> Add a player to the BDD</Accordion.Header>
            <Accordion.Body>
              <Form onSubmit={handleAddPlayer}>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Control
                        type="string"
                        placeholder="Enter player slug"
                        onChange={(e) => setPlayerSlug(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Button
                      variant="outline-success"
                      type="submit"
                      //disabled={!validateForm()}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Accordion.Body>
          </Accordion.Item>
        ) : (
          ""
        )}
      </Accordion>
    </div>
  );
};

export default SearchPlayer;
