import React from "react";
import { DateTime } from "luxon";

import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  BarChart,
  Bar,
  Brush,
  ResponsiveContainer,
} from "recharts";

const CustomTooltip = ({ active, payload }) => {
  if (!active) return null;
  console.log(payload);
  return (
    <div
      style={{
        background: "white",
      }}
    >
      {DateTime.fromISO(payload[0].payload.date).toLocaleString()}
      <br />
      <span style={{ color: "red" }}>
        Buys : #{payload[0].payload.nbDailyBuys} : {payload[0].value} eth
      </span>
      <br />
      <span style={{ color: "green" }}>
        Sells : #{payload[1].payload.nbDailySells} : {payload[1].value} eth
      </span>
    </div>
  );
};

const DailyActivityChart = (props) => {
  console.log(props);
  let data = props.dailyActivity;
  let avgSell = props.avgSell * 1;
  let avgBuy = props.avgBuy * 1;
  console.log(data);
  const renderLineChart = (
    <ResponsiveContainer width="100%" height={350}>
      <BarChart
        data={data}
        fontSize={12}
        margin={{
          top: 5,
          right: 0,
          left: 20,
          bottom: 5,
        }}
        height={250}
      >
        <Bar dataKey="totalDailyBuys" fill="red" />
        <Bar dataKey="totalDailySells" fill="green" />
        <Tooltip content={CustomTooltip} />
        <XAxis dataKey="month" />

        <YAxis
          width={20}
          angle={-50}
          type="number"
          domain={([dataMin, dataMax]) => [
            5 * avgBuy.toFixed(1) * 1,
            5 * avgSell.toFixed(1) * 1,
          ]}
        />
        <ReferenceLine y={0} stroke="#000" />

        <Brush
          dataKey="date"
          height={30}
          stroke="#8884d8"
          tickFormatter={(date) =>
            DateTime.fromISO(date).toFormat("dd/MM/yyyy")
          }
        />
      </BarChart>
    </ResponsiveContainer>
  );

  return renderLineChart;
};

export default DailyActivityChart;
