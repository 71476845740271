import React, { useState, useEffect, useMemo, useContext } from "react";
import { BsCheck, BsTrash, BsPencil } from "react-icons/bs";
import axios from "axios";
import {
  Row,
  Col,
  Button,
  Container,
  Dropdown,
  DropdownButton,
  Badge,
  Modal,
  Form,
  Spinner,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { AuthContext } from "../Auth/auth";
import authHeader from "../../services/auth-header";

import CustomTable from "../Tables/Table";
import StrategiesBulkEdit from "../Actions/StrategiesBulkEdit";
import EditStrategy from "../Actions/EditStrategy";
import DeleteStrategy from "../Actions/DeleteStrategy";
import SetAutoBidCoef from "../Actions/setAutobidCoef";
import SetCurrentYearCoef from "../Actions/setYearCoef";
import CopyStrategiesToRarity from "../Actions/CopyStrategiesToRarity";
import { NumberRangeColumnFilter } from "../Tables/Filter";
import { DateTime } from "luxon";

const StrategyList = () => {
  const { isAuthenticated, checkAuth, isLoading, role } =
    useContext(AuthContext);
  const [refresh, setRefresh] = useState("");
  const [reload, setReload] = useState("");
  const [nbMonth, setNbMonth] = useState([1]);
  const [nbGames, setNbGames] = useState([15]);
  const [bidCoef, setBidCoef] = useState("");
  const [yearCoef, setYearCoef] = useState("");
  const [strats, setStrats] = useState([]);
  const [lastUpdate, setLastUpdate] = useState("");

  const [rowList, setRowList] = useState([]);

  const [editRow, setEditRow] = useState([]);
  const [showStrategy, setShowStrategy] = useState(false);

  const [deleteRow, setDeleteRow] = useState([]);
  const [showDelete, setShowDelete] = useState(false);

  useEffect(() => {
    axios
      .get("/sorare/userStrategyList", { headers: authHeader() })
      .then(({ data }) => {
        console.log(data);
        if (data) {
          setStrats(data.strategiesList);
          setBidCoef(data.bidCoef * 1);
          setYearCoef(data.yearCoef * 1);
          setLastUpdate(data.lastUpdate);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [reload]);

  const columns = useMemo(
    () => [
      {
        // first group - Player info
        Header: "Player",

        columns: [
          {
            Header: "Name",
            accessor: "displayName",
            hidableColumn: true,
            Cell: (props) => {
              return (
                <Link
                  to={
                    "/player/" +
                    props.row.original.id +
                    "/" +
                    props.row.original.rarity
                  }
                >
                  {props.value}
                </Link>
              );
            },
          },
          {
            Header: "Club",
            accessor: "club",
            hidableColumn: true,
          },
          {
            Header: "Position",
            accessor: "position",
            hidableColumn: true,
          },
          {
            Header: "Status",
            accessor: "playingStatus",
            hidableColumn: true,
          },
          {
            Header: "Age",
            accessor: "age",
            hidableColumn: true,

            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
          },

          {
            Header: "Injury",
            accessor: "injury.active",
            hidableColumn: true,
            Cell: (props) => {
              return props.value ? <Col>🤕</Col> : <Col></Col>;
            },
          },
          {
            Header: "Injury Kind",
            accessor: "injury.kind",
            hidableColumn: true,
          },
          {
            Header: "Injury EndDate",
            accessor: "injury.expectedEndDate",
            hidableColumn: true,
          },
        ],
      },

      {
        Header: "Cards",

        columns: [
          {
            Header: "Nb",
            accessor: "nbCards",
            hidableColumn: true,
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
          },
          {
            Header: "Locked P",
            accessor: "lockedProfitPlayer",
            hidableColumn: true,
            disableFilters: true,
          },
        ],
      },
      {
        Header: "Stategy",

        columns: [
          {
            Header: "Rarity",
            accessor: "rarity",

            hidableColumn: true,
            Cell: (props) => {
              return props.value == "limited" ? (
                <Col>🟡</Col>
              ) : props.value == "rare" ? (
                <Col>🔴</Col>
              ) : (
                <Col>🔵</Col>
              );
            },
          },
          {
            Header: "Buy Strat",
            accessor: "buyStrategy",
            hidableColumn: true,
            Cell: (props) => {
              return (
                <DropdownButton
                  onSelect={(eventKey) =>
                    handleSelectBuyStrat(eventKey, props.row.original)
                  }
                  title={
                    props.value == "none"
                      ? "none"
                      : props.value == "autoBid"
                      ? "Auto Bid"
                      : props.value == "autoBidMM"
                      ? "Auto Bid MM"
                      : props.value == "manualBid"
                      ? "Manual Bid"
                      : props.value
                  }
                  id="perc"
                  size="sm"
                  variant={
                    props.value == "none"
                      ? "outline-info"
                      : props.value == "autoBid"
                      ? "outline-success"
                      : props.value == "autoBidMM"
                      ? "outline-danger"
                      : "outline-warning"
                  }
                >
                  <Dropdown.Item eventKey={"none"}> Do not Buy </Dropdown.Item>
                  <Dropdown.Item eventKey={"autoBid"}> Auto Bid </Dropdown.Item>
                  <Dropdown.Item eventKey={"autoBidMM"}>
                    {" "}
                    Auto Bid MM{" "}
                  </Dropdown.Item>
                  <Dropdown.Item eventKey={"manualBid"}>
                    {" "}
                    Manual Bid{" "}
                  </Dropdown.Item>
                </DropdownButton>
              );
            },
          },
          {
            Header: "Budget",
            accessor: "budget",
            hidableColumn: true,
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
            Cell: (props) => {
              return props.value !== "0.00000" ? props.value : "";
            },
          },
          {
            Header: "Used budget",
            accessor: "spentOnPlayer",
            hidableColumn: true,
            disableFilters: true,
            Cell: (props) => {
              return props.value * 1 + props.row.original.autoBid * 1 >
                props.row.original.budget * 1 ? (
                <p style={{ color: "red" }}> {props.value}</p>
              ) : props.value * 1 + props.row.original.autoBid * 1 <
                  props.row.original.budget * 1 &&
                props.value * 1 + 2 * props.row.original.autoBid * 1 >
                  props.row.original.budget * 1 ? (
                <p style={{ color: "orange" }}> {props.value}</p>
              ) : (
                <p style={{ color: "green" }}> {props.value}</p>
              );
            },
          },
          {
            Header: "Buy able",
            accessor: "buyableCards",
            hidableColumn: true,

            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
          },
          {
            Header: "Safety Bid",
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
            accessor: "safetyBid",
            hidableColumn: true,
          },
          {
            Header: "Manual Bid",
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
            hidableColumn: true,
            accessor: "manualBid",
            Cell: (props) => {
              return props.value !== "0.00000" ? props.value : "";
            },
          },
          {
            Header: "Auto Bid",
            hidableColumn: true,
            accessor: "autoBid",
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
          },
          {
            Header: "Auto Bid MM",
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
            hidableColumn: true,
            accessor: "autoBidMM",
          },
          {
            Header: "Sell Strat",
            accessor: "sellStrategy",
            hidableColumn: true,
            Cell: (props) => {
              return (
                <DropdownButton
                  onSelect={(eventKey) =>
                    handleSelectSellStrat(eventKey, props.row.original)
                  }
                  title={
                    props.value == "none"
                      ? "none"
                      : props.value == "classicHodl"
                      ? "MT Hodl"
                      : props.value == "strongHodl"
                      ? "LT Hodl"
                      : props.value == "sellAllAtPerc"
                      ? "Sell All @  Profit%"
                      : props.value == "sell1AtPerc"
                      ? "Sell 1 @ Profit%"
                      : props.value == "sellAllAtTarget"
                      ? "Sell All @ Target"
                      : props.value == "sell1AtTarget"
                      ? "Sell 1 @ Target"
                      : props.value
                  }
                  id="perc"
                  size="sm"
                  variant={
                    props.value == "none"
                      ? "outline-info"
                      : props.value == "strongHodl"
                      ? "outline-success"
                      : props.value == "classicHodl"
                      ? "outline-warning"
                      : props.value == "sellAllAtPerc"
                      ? "outline-danger"
                      : props.value == "sell1AtPerc"
                      ? "outline-primary"
                      : "outline-secondary"
                  }
                >
                  <Dropdown.Item eventKey={"none"}> Do not sell </Dropdown.Item>
                  <Dropdown.Item eventKey={"classicHodl"}>
                    {" "}
                    MT Hodl{" "}
                  </Dropdown.Item>
                  <Dropdown.Item eventKey={"strongHodl"}>
                    {" "}
                    LT Hodl{" "}
                  </Dropdown.Item>

                  <Dropdown.Item eventKey={"sellAllAtPerc"}>
                    {" "}
                    Sell All @ Profit%{" "}
                  </Dropdown.Item>
                  <Dropdown.Item eventKey={"sell1AtPerc"}>
                    {" "}
                    Sell 1 @ Profit%{" "}
                  </Dropdown.Item>
                  <Dropdown.Item eventKey={"sellAllAtTarget"}>
                    {" "}
                    Sell All @ Target{" "}
                  </Dropdown.Item>
                  <Dropdown.Item eventKey={"sell1AtTarget"}>
                    {" "}
                    Sell 1 @ Target{" "}
                  </Dropdown.Item>
                </DropdownButton>
              );
            },
          },
          {
            Header: "Expected Nb",
            disableFilters: true,
            hidableColumn: true,
            accessor: "stratPerc.expectedNB",
          },

          {
            Header: "Next Sell Min Profit",
            disableFilters: true,
            hidableColumn: true,
            accessor: "nextPerc",
            Cell: (props) => {
              return props.row.original.sellStrategy == "sellAllAtPerc" ||
                props.row.original.sellStrategy == "sell1AtPerc" ? (
                <DropdownButton
                  onSelect={(eventKey) =>
                    handleSelectPercentage(eventKey, props.row.original)
                  }
                  title={
                    (100 * (props.row.original.sellAtPerc - 1)).toFixed(0) + "%"
                  }
                  id="perc"
                  size="sm"
                  variant={
                    props.value == "1"
                      ? "outline-danger"
                      : props.value == "1.1"
                      ? "outline-warning"
                      : "outline-success"
                  }
                >
                  <Dropdown.Item eventKey={1}> 0 % </Dropdown.Item>
                  <Dropdown.Item eventKey={1.1}> 10 % </Dropdown.Item>
                  <Dropdown.Item eventKey={1.2}> 20 % </Dropdown.Item>
                  <Dropdown.Item eventKey={1.3}> 30 % </Dropdown.Item>
                  <Dropdown.Item eventKey={1.4}> 40 % </Dropdown.Item>
                  <Dropdown.Item eventKey={1.75}> 75 % </Dropdown.Item>
                  <Dropdown.Item eventKey={2}> 100 % </Dropdown.Item>
                  <Dropdown.Item eventKey={3}> 200 % </Dropdown.Item>
                </DropdownButton>
              ) : props.row.original.stratPerc?.coef ? (
                ((props.row.original.stratPerc?.coef - 1) * 100).toFixed(0) +
                "%"
              ) : (
                ""
              );
            },
          },
          {
            Header: "Target Sell",
            disableFilters: true,
            hidableColumn: true,
            accessor: "targetSell",
          },
          {
            Header: "Auto Sell MM",
            disableFilters: true,
            hidableColumn: true,
            accessor: "autoSellMM",
          },
        ],
      },
      {
        Header: "Actions",

        columns: [
          {
            Header: "Edit",
            accessor: "Edit",
            hidableColumn: true,
            disableFilters: true,
            Cell: (props) => {
              return (
                <div>
                  <Button
                    size="sm"
                    variant="outline-warning"
                    onClick={() => {
                      console.log(props.row.original);
                      setEditRow(props.row.original);
                      handleShowStrategy();
                    }}
                  >
                    <BsPencil style={{ color: "orange", fontSize: "15px" }} />
                  </Button>
                </div>
              );
            },
          },
          {
            Header: "Del",
            accessor: "Delete",
            hidableColumn: true,
            disableFilters: true,
            Cell: (props) => {
              return (
                <div>
                  <Col>
                    <Button
                      size="sm"
                      variant="outline-danger"
                      onClick={() => {
                        console.log(props.row.original);
                        handleShowDelete();
                        setDeleteRow(props.row.original);
                      }}
                    >
                      <BsTrash style={{ color: "red", fontSize: "15px" }} />
                    </Button>
                  </Col>
                </div>
              );
            },
          },
          {
            accessor: "published",
            hidableColumn: true,
            disableFilters: true,
            Cell: (props) => {
              if (props.value == "Pending") {
                return <Spinner animation="border" />;
              } else if (props.value == "Published") {
                return <BsCheck style={{ color: "green", fontSize: "35px" }} />;
              } else {
                return "";
              }
            },
          },
        ],
      },

      {
        // Second group - stats
        Header: "Stats",

        // Second group columns
        columns: [
          {
            Header: "Name",
            accessor: "doubleName",
            disableFilters: true,
            hidableColumn: true,
            Cell: (props) => {
              return props.row.original.displayName;
            },
          },
          {
            Header: "Card Supply",
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
            hidableColumn: true,
            accessor: "cardSupply",
          },
          {
            Header: "Market Cap TgyKTor",
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
            hidableColumn: true,
            accessor: "marketCap",
          },
          {
            Header: "Nb Trans",
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
            hidableColumn: true,
            accessor: "stats.month" + nbMonth + ".nbTrans",
          },
          {
            Header: "Min",
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
            hidableColumn: true,
            accessor: "stats.month" + nbMonth + ".minPrice",
          },
          {
            Header: "Avg",
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
            hidableColumn: true,
            accessor: "stats.month" + nbMonth + ".avgPrice",
          },
          {
            Header: "Avg5T",
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
            hidableColumn: true,
            accessor: "stats.month" + nbMonth + ".avgPrice5Trans",
          },

          {
            Header: "Max",
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
            hidableColumn: true,
            accessor: "stats.month" + nbMonth + ".maxPrice",
          },
          {
            Header: "Last Price",
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
            hidableColumn: true,
            accessor: "stats.month" + nbMonth + ".lastPrice",
          },
          {
            Header: "LPQ",
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
            hidableColumn: true,
            accessor: "stats.month" + nbMonth + ".lastPriceQuantile",
          },
          {
            Header: "Q10",
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
            hidableColumn: true,
            accessor: "stats.month" + nbMonth + ".quantile10",
          },
          {
            Header: "Q20",
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
            hidableColumn: true,
            accessor: "stats.month" + nbMonth + ".quantile20",
          },
          {
            Header: "Q80",
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
            hidableColumn: true,
            accessor: "stats.month" + nbMonth + ".quantile80",
          },
          {
            Header: "Q90",
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
            hidableColumn: true,
            accessor: "stats.month" + nbMonth + ".quantile90",
          },
          {
            Header: "Std Dev",
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
            hidableColumn: true,
            accessor: "stats.month" + nbMonth + ".standardDev",
          },
          {
            Header: "Lvg Ratio",
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
            hidableColumn: true,
            accessor: "stats.month" + nbMonth + ".lvgRatio",
          },
        ],
      },
      {
        Header: "Predictions",
        columns: [
          {
            Header: "Last Predicted Price",
            hidableColumn: true,
            accessor: "lastEstimatedPrice.predictedPrice",
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
            Cell: (props) => {
              return (
                <div>
                  <span> {props.value}</span>
                  <span style={{ fontSize: 10 }}>
                    {" "}
                    {props?.row?.original?.lastEstimatedPrice?.date
                      ? DateTime.fromISO(
                          props?.row?.original?.lastEstimatedPrice?.date
                        ).toLocaleString()
                      : ""}
                  </span>
                </div>
              );
            },
          },
          {
            Header: "Avg Predicted Price",
            hidableColumn: true,
            accessor: "scoreStats.nbGame" + nbGames + ".avgPredictedPrice",
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
          },
          {
            Header: "Last Theo Price",
            hidableColumn: true,
            accessor: "lastEstimatedPrice.theoPrice",
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
            Cell: (props) => {
              return (
                <div>
                  <span> {props.value}</span>
                  <span style={{ fontSize: 10 }}>
                    {" "}
                    {props?.row?.original?.lastEstimatedPrice?.date
                      ? DateTime.fromISO(
                          props?.row?.original?.lastEstimatedPrice?.date
                        ).toLocaleString()
                      : ""}
                  </span>
                </div>
              );
            },
          },
          {
            Header: "Avg Theo Price",
            hidableColumn: true,
            accessor: "scoreStats.nbGame" + nbGames + ".avgTheoPrice",
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
          },
        ],
      },
      {
        Header: "Ratios",

        columns: [
          {
            Header: "Name",
            hidableColumn: true,
            disableFilters: true,
            accessor: "tripleName",
            Cell: (props) => {
              return props.row.original.displayName;
            },
          },
          {
            Header: " Last Predicted / Avg5T",
            hidableColumn: true,
            accessor: "scoreStats.nbGame" + nbGames + ".ratioPredReal",
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
          },
          {
            Header: "Avg 🟡/ Avg 🔴",
            hidableColumn: true,
            accessor: "ratioYR",
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
          },
          {
            Header: "JP Score",
            hidableColumn: true,
            accessor: "scoreStats.nbGame" + nbGames + ".jpScore",
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
          },
          {
            Header: "JP / Price",
            hidableColumn: true,
            accessor: "scoreStats.nbGame" + nbGames + ".ratioJPPrice",
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
          },
        ],
      },
      {
        Header: "Game Stats",

        columns: [
          {
            Header: "Nb Games",
            accessor: "scoreStats.nbGame" + nbGames + ".nbGames",
            hidableColumn: true,
            disableFilters: true,
          },
          {
            Header: "Nb Appear",
            accessor: "scoreStats.nbGame" + nbGames + ".nbAppearance",
            hidableColumn: true,
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
          },
          {
            Header: "Avg Score",
            accessor: "scoreStats.nbGame" + nbGames + ".avgScore",
            hidableColumn: true,
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
          },
          {
            Header: "Std Dev",
            accessor: "scoreStats.nbGame" + nbGames + ".standardDev",
            hidableColumn: true,
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
          },
        ],
      },
    ],
    [nbMonth, nbGames, strats, refresh]
  );

  const handleSelectMonth = (e) => {
    setNbMonth(e);
  };
  const handleSelectNbGames = (e) => {
    setNbGames(e);
  };

  const handleSelectSellStrat = (event, row) => {
    let sellStrat = event;

    let index = strats.findIndex(
      (element) => element.id == row.id && element.rarity == row.rarity
    );
    let newStrats = [...strats];

    newStrats[index].sellStrategy = sellStrat;
    newStrats[index].published = "Pending";

    setStrats(newStrats);

    axios
      .post(
        "/sorare/editPlayerStrategy/",
        {
          playerId: row.id,
          rarity: row.rarity,
          sellStrategy: newStrats[index].sellStrategy,
        },
        { headers: authHeader() }
      )
      .then(({ data }) => {
        console.log(data);
        if (data.updated) {
          console.log("published");
          newStrats[index].published = "Published";
          setRefresh(refresh + 1);
          setStrats(newStrats);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSelectBuyStrat = (event, row) => {
    let buyStrat = event;

    let index = strats.findIndex(
      (element) => element.id == row.id && element.rarity == row.rarity
    );
    let newStrats = [...strats];

    newStrats[index].buyStrategy = buyStrat;
    newStrats[index].published = "Pending";

    setStrats(newStrats);

    axios
      .post(
        "/sorare/editPlayerStrategy/",
        {
          playerId: row.id,
          rarity: row.rarity,
          buyStrategy: newStrats[index].buyStrategy,
        },
        { headers: authHeader() }
      )
      .then(({ data }) => {
        console.log(data);
        if (data.updated) {
          console.log("published");
          newStrats[index].published = "Published";
          setRefresh(refresh + 1);
          setStrats(newStrats);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSelectPercentage = (event, row) => {
    let perc = event;

    let index = strats.findIndex(
      (element) => element.id == row.id && element.rarity == row.rarity
    );
    let newStrats = [...strats];

    newStrats[index].sellAtPerc = perc;
    newStrats[index].nextPerc = perc;
    newStrats[index].published = "Pending";

    setStrats(newStrats);

    axios
      .post(
        "/sorare/editPlayerStrategy/",
        {
          playerId: row.id,
          rarity: row.rarity,
          sellAtPerc: newStrats[index].sellAtPerc,
        },
        { headers: authHeader() }
      )
      .then(({ data }) => {
        console.log(data);
        if (data.updated) {
          console.log("published");
          newStrats[index].published = "Published";

          setStrats(newStrats);
          setRefresh(refresh + 1);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleShowStrategy = () => {
    setShowStrategy(true);
  };

  const handleShowDelete = () => {
    setShowDelete(true);
  };

  return isLoading ? (
    <Spinner animation="border" />
  ) : (
    <div>
      <Row>
        <Col className="justify-content-start">
          <DropdownButton
            onSelect={handleSelectMonth}
            title={"Nb month : " + nbMonth}
            id="sortByScroll"
            variant="outline-success"
            style={{ marginBottom: 10 }}
          >
            <Dropdown.Item eventKey={1}>1 month</Dropdown.Item>
            <Dropdown.Item eventKey={3}> 3 months </Dropdown.Item>
            <Dropdown.Item eventKey={6}> 6 months </Dropdown.Item>
          </DropdownButton>
        </Col>
        <Col className="justify-content-start">
          <DropdownButton
            onSelect={handleSelectNbGames}
            title={"Nb Games : " + nbGames}
            id="sortByScroll"
            variant="outline-success"
            style={{ marginBottom: 10 }}
          >
            <Dropdown.Item eventKey={5}> 5 Games</Dropdown.Item>
            <Dropdown.Item eventKey={10}> 10 Games </Dropdown.Item>
            <Dropdown.Item eventKey={15}> 15 Games </Dropdown.Item>
            <Dropdown.Item eventKey={20}> 20 Games</Dropdown.Item>
            <Dropdown.Item eventKey={30}> 30 Games</Dropdown.Item>
          </DropdownButton>
        </Col>
        <Col>
          <SetAutoBidCoef
            bidCoef={bidCoef}
            setBidCoef={setBidCoef}
            reload={reload}
            setReload={setReload}
          />
        </Col>
        <Col>
          <SetCurrentYearCoef yearCoef={yearCoef} setYearCoef={setYearCoef} />
        </Col>
        <Col style={{ justifyContent: "end" }}>
          {" "}
          <Badge
            bg="light"
            text="dark"
            pill="true"
            style={{ fontSize: 15, marginBottom: 10 }}
          >
            Last Update: {new Date(lastUpdate).toLocaleString("fr-FR")}
          </Badge>
        </Col>
      </Row>
      <Row>
        {
          <CustomTable
            columns={columns}
            data={strats}
            setRowList={setRowList}
            hiddenCols={[
              "club",
              "position",
              "playingStatus",
              "injury.active",
              "injury.kind",
              "injury.expectedEndDate",
              "safetyBid",
              "manualBid",
              "autoBidMM",
              "autoSellMM",
              "cardSupply",
              "marketCap",
              "stratPerc.expectedNB",
              "stats.month1.avgPrice",
              "stats.month1.nbTrans",
              "stats.month1.minPrice",
              "stats.month1.maxPrice",
              "stats.month1.lastPrice",
              "stats.month1.lastPriceQuantile",
              "stats.month1.quantile10",
              "stats.month1.quantile20",
              "stats.month1.quantile80",
              "stats.month1.quantile90",
              "stats.month1.standardDev",
              "stats.month1.lvgRatio",
              "tripleName",
              "scoreStats.nbGame15.nbGames",
            ]}
            tableHeight="90vh"
          />
        }
      </Row>

      <EditStrategy
        editRow={editRow}
        setEditRow={setEditRow}
        showStrategy={showStrategy}
        setShowStrategy={setShowStrategy}
        setStrats={setStrats}
        strats={strats}
        refresh={refresh}
        setRefresh={setRefresh}
      />
      <Row>
        <Col>
          <StrategiesBulkEdit rowList={rowList} />
        </Col>
        <Col>
          <CopyStrategiesToRarity rowList={rowList} />
        </Col>
      </Row>
      <DeleteStrategy
        deleteRow={deleteRow}
        showDelete={showDelete}
        setShowDelete={setShowDelete}
        setStrats={setStrats}
        strats={strats}
        refresh={refresh}
        setRefresh={setRefresh}
      />
    </div>
  );
};

export default StrategyList;
