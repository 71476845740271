import { Container, Spinner } from "react-bootstrap";
import React, { useState, useMemo, useContext } from "react";
import { AuthContext } from "../Auth/auth";
import { Navigate, useLocation } from "react-router-dom";

const Home = () => {
  const { isAuthenticated, checkAuth, isLoading, role } =
    useContext(AuthContext);

  return isLoading ? (
    <Spinner animation="border" />
  ) : isAuthenticated ? (
    role === "viewer" || role === "editor" ? (
      <Navigate to="/user-stats" />
    ) : role === "scout" ? (
      <Navigate to="/search-player" />
    ) : (
      <div>
        {" "}
        <h1> Bienvenue sur botSorare</h1>
        <p>
          {" "}
          Type d'utilisateur en attente de validation par l'administrateur{" "}
        </p>
      </div>
    )
  ) : (
    <div>
      {" "}
      <h1> Bienvenue sur botSorare </h1>
      <p> Veuillez vous connecter ou créer un compte</p>
    </div>
  );
};

export default Home;
