import React, { useState, useMemo, useContext } from "react";
import { AuthContext } from "../Auth/auth";
import Select from "react-select";
import { BsTrash, BsPlusLg } from "react-icons/bs";
import axios from "axios";
import {
  Row,
  Col,
  Button,
  Dropdown,
  DropdownButton,
  Badge,
  Spinner,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import authHeader from "../../services/auth-header";

import CustomTable from "../Tables/Table";

import EditStrategy from "../Actions/EditStrategy";
import DeleteStrategy from "../Actions/DeleteStrategy";

import { NumberRangeColumnFilter } from "../Tables/Filter";
import { DateTime } from "luxon";

const CompetitionPlayerList = () => {
  const { isAuthenticated, checkAuth, isLoading, role } =
    useContext(AuthContext);
  const [refresh, setRefresh] = useState("");
  const [nbMonth, setNbMonth] = useState([1]);
  const [nbGames, setNbGames] = useState([15]);

  const [players, setPlayers] = useState([]);
  const [lastUpdate, setLastUpdate] = useState("");

  const [rowList, setRowList] = useState([]);

  const [editRow, setEditRow] = useState([]);

  const [showStrategy, setShowStrategy] = useState(false);

  const [deleteRow, setDeleteRow] = useState([]);
  const [showDelete, setShowDelete] = useState(false);

  const [isDataLoading, setIsDataLoading] = useState(false);

  const [rarity, setRarity] = useState("limited");
  const [selectedLeagues, setSelectedLeagues] = useState([]);
  const [selectedRarity, setSelectedRarity] = useState([]);
  const leagueOptions = [
    { slug: "ligue-1-fr", label: "Ligue 1" },
    { slug: "serie-a-it", label: "Serie A" },
    { slug: "bundesliga-de", label: "BundesLiga" },
    { slug: "premier-league-gb-eng", label: "Premier League" },
    { slug: "laliga-santander", label: "Liga" },
  ];
  const rarityOptions = [
    { value: "limited", label: "Limited" },
    { value: "rare", label: "Rare" },
    { value: "super_rare", label: "Super Rare" },
  ];

  const columns = useMemo(
    () => [
      {
        // first group - Player info
        Header: "Player",

        columns: [
          {
            Header: "Name",
            accessor: "displayName",
            hidableColumn: true,
            Cell: (props) => {
              return (
                <Link to={"/player/" + props.row.original.id + "/" + rarity}>
                  {props.value}
                </Link>
              );
            },
          },
          {
            Header: "Club",
            accessor: "club",
            hidableColumn: true,
            show: false,
          },
          {
            Header: "League",
            accessor: "league",
            hidableColumn: true,
            show: false,
          },
          {
            Header: "Position",
            accessor: "position",
            hidableColumn: true,
          },
          {
            Header: "Age",
            accessor: "age",
            hidableColumn: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
          },
          {
            Header: "Status",
            accessor: "playingStatus",
            hidableColumn: true,
          },
          {
            Header: "Injury",
            accessor: "plInjury.active",
            hidableColumn: true,
            Cell: (props) => {
              return props.value ? <Col>🤕</Col> : <Col></Col>;
            },
          },
          {
            Header: "Injury Kind",
            accessor: "plInjury.kind",
            hidableColumn: true,
            show: false,
          },
          {
            Header: "Injury EndDate",
            accessor: "plInjury.expectedEndDate",
            hidableColumn: true,
            show: false,
          },
        ],
      },

      {
        // Second group - stats
        Header: "Stats",

        // Second group columns
        columns: [
          {
            Header: "Name",
            accessor: "doubleName",
            hidableColumn: true,
            Cell: (props) => {
              return props.row.original.displayName;
            },
          },
          {
            Header: "Nb Cards",
            disableFilters: true,
            hidableColumn: true,
            accessor: "userPlayerCards",
          },
          role === "editor"
            ? {
                Header: "Strategy",
                disableFilters: true,
                hidableColumn: true,
                accessor: "strategy",
                Cell: (props) => {
                  return props.value ? (
                    <div>
                      <Col>
                        <Button
                          size="sm"
                          variant="outline-danger"
                          onClick={() => {
                            console.log(props.row.original);
                            handleShowDelete();
                            setDeleteRow(props.row.original);
                          }}
                        >
                          <BsTrash style={{ color: "red", fontSize: "15px" }} />
                        </Button>
                      </Col>
                    </div>
                  ) : (
                    <Button
                      size="sm"
                      variant="outline-success"
                      onClick={() => {
                        console.log(props.row.original);
                        setEditRow(props.row.original);
                        handleShowStrategy();
                      }}
                    >
                      <BsPlusLg style={{ color: "green", fontSize: "15px" }} />{" "}
                      {props.row.original.rarity == "limited"
                        ? "🟡"
                        : props.row.original.rarity == "rare"
                        ? "🔴"
                        : "🔵"}
                    </Button>
                  );
                },
              }
            : { Header: " " },
          {
            Header: "Nb Trans",
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
            hidableColumn: true,
            accessor: "stats.month" + nbMonth + ".nbTrans",
          },
          {
            Header: "Min",
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
            hidableColumn: true,
            accessor: "stats.month" + nbMonth + ".minPrice",
            show: false,
          },
          {
            Header: "Avg1m",
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
            hidableColumn: true,
            accessor: "stats.month" + nbMonth + ".avgPrice",
            show: false,
          },
          {
            Header: "Avg5T",
            //disableFilters: true,
            hidableColumn: true,
            accessor: "stats.month" + nbMonth + ".avgPrice5Trans",
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
          },
          {
            Header: "Max",
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
            hidableColumn: true,
            accessor: "stats.month" + nbMonth + ".maxPrice",
            show: false,
          },
          {
            Header: "Last Price",
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
            hidableColumn: true,
            accessor: "stats.month" + nbMonth + ".lastPrice",
          },
          {
            Header: "LPQ",
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
            hidableColumn: true,
            accessor: "stats.month" + nbMonth + ".lastPriceQuantile",
            show: false,
          },
          {
            Header: "Q10",
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
            hidableColumn: true,
            accessor: "stats.month" + nbMonth + ".quantile10",
            show: false,
          },
          {
            Header: "Q20",
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
            hidableColumn: true,
            accessor: "stats.month" + nbMonth + ".quantile20",
            show: false,
          },
          {
            Header: "Q80",
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
            hidableColumn: true,
            accessor: "stats.month" + nbMonth + ".quantile80",
            show: false,
          },
          {
            Header: "Q90",
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
            hidableColumn: true,
            accessor: "stats.month" + nbMonth + ".quantile90",
            show: false,
          },
          {
            Header: "Std Dev",
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
            hidableColumn: true,
            accessor: "stats.month" + nbMonth + ".standardDev",
            show: false,
          },
          {
            Header: "Lvg Ratio",
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
            hidableColumn: true,
            accessor: "stats.month" + nbMonth + ".lvgRatio",
            show: false,
          },
        ],
      },
      {
        Header: "Predictions",
        columns: [
          {
            Header: "Last Predicted Price",
            hidableColumn: true,
            accessor: "lastEstimatedPrice.predictedPrice",
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
            Cell: (props) => {
              return (
                <div>
                  <span> {props.value}</span>
                  <span style={{ fontSize: 10 }}>
                    {" "}
                    {props?.row?.original?.lastEstimatedPrice?.date
                      ? DateTime.fromISO(
                          props?.row?.original?.lastEstimatedPrice?.date
                        ).toLocaleString()
                      : ""}
                  </span>
                </div>
              );
            },
          },
          {
            Header: "Avg Predicted Price",
            hidableColumn: true,
            accessor: "scoreStats.nbGame" + nbGames + ".avgPredictedPrice",
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
          },
          {
            Header: "Last Theo Price",
            hidableColumn: true,
            accessor: "lastEstimatedPrice.theoPrice",
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
            Cell: (props) => {
              return (
                <div>
                  <span> {props.value}</span>
                  <span style={{ fontSize: 10 }}>
                    {" "}
                    {props?.row?.original?.lastEstimatedPrice?.date
                      ? DateTime.fromISO(
                          props?.row?.original?.lastEstimatedPrice?.date
                        ).toLocaleString()
                      : ""}
                  </span>
                </div>
              );
            },
          },
          {
            Header: "Avg Theo Price",
            hidableColumn: true,
            accessor: "scoreStats.nbGame" + nbGames + ".avgTheoPrice",
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
          },
        ],
      },

      {
        Header: "Ratios",

        columns: [
          {
            Header: "Name",
            hidableColumn: true,
            accessor: "tripleName",
            Cell: (props) => {
              return props.row.original.displayName;
            },
            show: false,
          },
          {
            Header: " Last Predicted / Avg5T",
            hidableColumn: true,
            accessor: "scoreStats.nbGame" + nbGames + ".ratioPredReal",
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
          },
          {
            Header: "Avg 🟡/ Avg 🔴",

            hidableColumn: true,
            accessor: "ratioYR",
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
          },
          {
            Header: "JP Score",
            hidableColumn: true,
            accessor: "scoreStats.nbGame" + nbGames + ".jpScore",
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
          },
          {
            Header: "JP / Price",
            hidableColumn: true,
            accessor: "scoreStats.nbGame" + nbGames + ".ratioJPPrice",
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
          },
        ],
      },
      {
        Header: "Game Stats",

        columns: [
          {
            Header: "Nb Games",
            accessor: "scoreStats.nbGame" + nbGames + ".nbGames",
            hidableColumn: true,
            disableFilters: true,
            show: false,
          },
          {
            Header: "Nb Appear",
            accessor: "scoreStats.nbGame" + nbGames + ".nbAppearance",
            hidableColumn: true,
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
          },
          {
            Header: "Avg Score",
            accessor: "scoreStats.nbGame" + nbGames + ".avgScore",
            hidableColumn: true,
            //disableFilters: true,
            Filter: NumberRangeColumnFilter,
            filter: "between",
          },
          {
            Header: "Std Dev",
            accessor: "scoreStats.nbGame" + nbGames + ".standardDev",
            hidableColumn: true,
            disableFilters: true,
          },
        ],
      },
    ],
    [nbMonth, nbGames, players, rarity, refresh]
  );

  const handleSelectMonth = (e) => {
    setNbMonth(e);
  };
  const handleSelectNbGames = (e) => {
    setNbGames(e);
  };

  const handleShowStrategy = () => {
    setShowStrategy(true);
  };

  const handleShowDelete = () => {
    setShowDelete(true);
  };

  const onchangeSelectLeague = (items) => {
    setSelectedLeagues(items);
    console.log(items);
  };
  const fetchPlayerList = () => {
    setIsDataLoading(true);
    axios
      .post(
        "/sorare/fetchPlayerList",
        { competitionSlugs: selectedLeagues, rarity: selectedRarity.value },
        { headers: authHeader() }
      )
      .then(({ data }) => {
        console.log(data);
        setIsDataLoading(false);
        setPlayers(data.playersList);
        setLastUpdate(data.lastUpdate);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleSelectRarity = (item) => {
    setSelectedRarity(item);
  };

  const validateSearch = () => {
    return selectedLeagues.length !== 0 && selectedRarity.value;
  };
  return isLoading ? (
    <Spinner animation="border" />
  ) : (
    <div>
      <Row style={{ marginBottom: 10 }}>
        <Col>
          <Select
            options={leagueOptions}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            onChange={onchangeSelectLeague}
            getOptionValue={(option) => option.slug}
            getOptionLabel={(option) => option.label}
            styles={{ menu: (styles) => ({ ...styles, zIndex: 999 }) }}
          />
        </Col>
        <Col xs={2}>
          <Select
            options={rarityOptions}
            hideSelectedOptions={false}
            onChange={handleSelectRarity}
            getOptionValue={(option) => option.value}
            getOptionLabel={(option) => option.label}
            styles={{
              menu: (styles) => ({ ...styles, zIndex: 999 }),
            }}
          />
        </Col>
        <Col>
          {isDataLoading ? (
            <Spinner animation="border" />
          ) : (
            <Button
              variant="outline-success"
              type="submit"
              disabled={!validateSearch()}
              onClick={fetchPlayerList}
            >
              Submit
            </Button>
          )}
        </Col>
      </Row>

      {players?.length !== 0 ? (
        <div>
          <hr />
          <Row xs={2} md={4}>
            <Col className="justify-content-start">
              <DropdownButton
                onSelect={handleSelectMonth}
                title={"Nb month : " + nbMonth}
                id="sortByScroll"
                variant="outline-success"
                style={{ marginBottom: 10 }}
              >
                <Dropdown.Item eventKey={1}> 1 month</Dropdown.Item>
                <Dropdown.Item eventKey={3}> 3 months </Dropdown.Item>
                <Dropdown.Item eventKey={6}> 6 months </Dropdown.Item>
              </DropdownButton>
            </Col>
            <Col className="justify-content-start">
              <DropdownButton
                onSelect={handleSelectNbGames}
                title={"Nb Games : " + nbGames}
                id="sortByScroll"
                variant="outline-success"
                style={{ marginBottom: 10 }}
              >
                <Dropdown.Item eventKey={5}> 5 Games</Dropdown.Item>
                <Dropdown.Item eventKey={10}> 10 Games </Dropdown.Item>
                <Dropdown.Item eventKey={15}> 15 Games </Dropdown.Item>
                <Dropdown.Item eventKey={20}> 20 Games</Dropdown.Item>
                <Dropdown.Item eventKey={30}> 30 Games</Dropdown.Item>
              </DropdownButton>
            </Col>

            <Col style={{ justifyContent: "end" }}>
              {" "}
              <Badge
                bg="light"
                text="dark"
                pill="true"
                style={{ fontSize: 15, marginBottom: 10 }}
              >
                Last Update: {new Date(lastUpdate).toLocaleString("fr-FR")}
              </Badge>
            </Col>
          </Row>
        </div>
      ) : (
        ""
      )}
      {players?.length !== 0 && !isDataLoading ? (
        <Row>
          <CustomTable
            columns={columns}
            data={players}
            setRowList={setRowList}
            hiddenCols={[
              "league",
              "club",
              "position",
              "playingStatus",
              "injury.active",
              "injury.kind",
              "injury.expectedEndDate",
              "safetyBid",
              "manualBid",
              "autoBidMM",
              "autoSellMM",
              "doubleName",
              "stats.month1.avgPrice",
              "stats.month1.nbTrans",
              "stats.month1.minPrice",
              "stats.month1.maxPrice",
              "stats.month1.lastPrice",
              "stats.month1.lastPriceQuantile",
              "stats.month1.quantile10",
              "stats.month1.quantile20",
              "stats.month1.quantile80",
              "stats.month1.quantile90",
              "stats.month1.standardDev",
              "stats.month1.lvgRatio",

              "scoreStats.nbGame15.nbGames",
            ]}
            tableHeight="90vh"
          />
        </Row>
      ) : (
        ""
      )}
      <EditStrategy
        editRow={editRow}
        setEditRow={setEditRow}
        showStrategy={showStrategy}
        setShowStrategy={setShowStrategy}
        setStrats={setPlayers}
        strats={players}
        refresh={refresh}
        setRefresh={setRefresh}
        competition={true}
      />
      <DeleteStrategy
        deleteRow={deleteRow}
        showDelete={showDelete}
        setShowDelete={setShowDelete}
        setStrats={setPlayers}
        strats={players}
        refresh={refresh}
        setRefresh={setRefresh}
        competition={true}
      />
    </div>
  );
};

export default CompetitionPlayerList;
