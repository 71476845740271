import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Button, Modal, Form } from "react-bootstrap";
import axios from "axios";

import authHeader from "../../services/auth-header.js";

const EditStrategy = (props) => {
  //Edit strategy
  const editRow = props.editRow;
  const setEditRow = props.setEditRow;

  const showStrategy = props.showStrategy;
  const setShowStrategy = props.setShowStrategy;

  const strats = props.strats;
  const setStrats = props.setStrats;

  const competition = props.competition;

  const refresh = props.refresh;
  const setRefresh = props.setRefresh;

  const [safetyBid, setSafetyBid] = useState("default");
  const [manualBid, setManualBid] = useState("default");
  const [targetSell, setTargetSell] = useState("default");
  const [budget, setBudget] = useState("default");
  const [buyStrategy, setBuyStrategy] = useState("default");
  const [sellStrategy, setSellStrategy] = useState("default");
  const [sellAtPerc, setSellAtPerc] = useState("default");

  const handleCloseStrategy = () => {
    setEditRow([]);
    setBuyStrategy("default");
    setSellStrategy("default");
    setBudget("default");
    setManualBid("default");
    setSafetyBid("default");
    setSellAtPerc("default");
    setTargetSell("default");
    setShowStrategy(false);
  };

  const handleSaveStrategy = (event) => {
    event.preventDefault();
    setShowStrategy(false);
    let index = strats.findIndex(
      (element) => element.id == editRow.id && element.rarity == editRow.rarity
    );
    setEditRow([]);
    let newStrats = [...strats];

    if (buyStrategy !== "default") {
      newStrats[index].buyStrategy = buyStrategy;
      newStrats[index].published = "Pending";
    }
    if (sellStrategy !== "default") {
      newStrats[index].sellStrategy = sellStrategy;
      newStrats[index].published = "Pending";
    }
    if (budget !== "default") {
      newStrats[index].budget = budget;
      newStrats[index].published = "Pending";
    }
    if (safetyBid !== "default") {
      newStrats[index].safetyBid = safetyBid;
      newStrats[index].published = "Pending";
    }
    if (manualBid !== "default") {
      newStrats[index].manualBid = manualBid;
      newStrats[index].published = "Pending";
    }
    if (targetSell !== "default") {
      newStrats[index].targetSell = targetSell;
      newStrats[index].published = "Pending";
    }
    if (sellAtPerc !== "default") {
      newStrats[index].sellAtPerc = sellAtPerc;
      newStrats[index].published = "Pending";
    }
    setStrats(newStrats);
    setBuyStrategy("default");
    setSellStrategy("default");
    setBudget("default");
    setManualBid("default");
    setSafetyBid("default");
    setSellAtPerc("default");
    setTargetSell("default");

    axios
      .post(
        "/sorare/editPlayerStrategy/",
        {
          playerId: editRow.id,
          rarity: editRow.rarity,
          buyStrategy: newStrats[index].buyStrategy,
          sellStrategy: newStrats[index].sellStrategy,
          safetyBid: newStrats[index].safetyBid,
          manualBid: newStrats[index].manualBid,
          budget: newStrats[index].budget,
          sellAtPerc: newStrats[index].sellAtPerc,
          targetSell: newStrats[index].targetSell,
        },
        { headers: authHeader() }
      )
      .then(({ data }) => {
        console.log(data);
        if (data.updated) {
          if (competition) {
            newStrats[index].strategy = {};
          }
          console.log("published");
          newStrats[index].published = "Published";
          setRefresh(refresh + 1);
          setStrats(newStrats);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={showStrategy}
      onHide={handleCloseStrategy}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {editRow.displayName} - {editRow.rarity}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSaveStrategy}>
          <Form.Group className="mb-3">
            <Form.Label>Buy Strategy</Form.Label>
            <Form.Select
              defaultValue={
                editRow.buyStrategy ? editRow.buyStrategy : "default"
              }
              onChange={(e) => setBuyStrategy(e.target.value)}
            >
              <option value="default"></option>
              <option value="autoBid">Buy @ Auto Bid</option>
              <option value="autoBidMM">Buy @ Auto Bid MM </option>
              <option value="manualBid">Buy @ Manual Bid</option>
              <option value="none"> Do not buy </option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasic">
            <Form.Label> Budget </Form.Label>
            <Form.Control
              type="number"
              step="0.0001"
              min="0"
              placeholder="Set Budget"
              defaultValue={editRow.budget}
              onChange={(e) => setBudget(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasic">
            <Form.Label> Safety Bid</Form.Label>
            <Form.Control
              type="number"
              step="0.0001"
              min="0"
              placeholder="Set Safety Bid"
              defaultValue={editRow.safetyBid}
              onChange={(e) => setSafetyBid(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasic">
            <Form.Label> Manual Bid</Form.Label>
            <Form.Control
              type="number"
              step="0.0001"
              min="0"
              placeholder="Set Manual Bid "
              defaultValue={editRow.manualBid}
              onChange={(e) => setManualBid(e.target.value)}
            />
          </Form.Group>
          <hr />
          <Form.Group className="mb-3" controlId="formBasic">
            <Form.Label>Sell Strategy</Form.Label>
            <Form.Select
              defaultValue={
                editRow.sellStrategy ? editRow.sellStrategy : "default"
              }
              onChange={(e) => setSellStrategy(e.target.value)}
            >
              <option value="default"></option>
              <option value="classicHodl">MT Hodl</option>
              <option value="strongHodl">LT Hodl</option>
              <option value="sellAllAtPerc">Sell All @ Profit%</option>
              <option value="sell1AtPerc">Sell 1 @ Profit%</option>
              <option value="sellAllAtTarget">Sell All @ Target</option>
              <option value="sell1AtTarget">Sell 1 @ Target</option>
              <option value="none"> None</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasic">
            <Form.Label>Sell At Percentage</Form.Label>
            <Form.Select
              defaultValue={editRow.sellAtPerc ? editRow.sellAtPerc : "default"}
              onChange={(e) => setSellAtPerc(e.target.value)}
            >
              <option value="default"></option>
              <option value="1">0%</option>
              <option value="1.1">10%</option>
              <option value="1.2">20%</option>
              <option value="1.3">30%</option>
              <option value="1.4"> 40%</option>
              <option value="1.75"> 75%</option>
              <option value="2"> 100%</option>
              <option value="3"> 200%</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasic">
            <Form.Label> Target Sell</Form.Label>
            <Form.Control
              type="number"
              step="0.0001"
              min="0"
              placeholder="Set Target Sell"
              defaultValue={editRow.targetSell}
              onChange={(e) => setTargetSell(e.target.value)}
            />{" "}
          </Form.Group>
          <Button
            variant="outline-success"
            type="submit"
            //disabled={!validateForm()}
          >
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditStrategy;
