import React, { useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { Form, Button, Container } from "react-bootstrap";
import { AuthContext } from "../Auth/auth";

const Login = () => {
  const { login } = React.useContext(AuthContext);

  let navigate = useNavigate();
  let location = useLocation();
  let { from } = location.state || { from: { pathname: "/" } };

  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState("");

  const onSubmit = (event) => {
    event.preventDefault();
    login(userEmail, password)
      .then(() => {
        navigate(from);
      })
      .catch((error) => console.log(error));
  };

  const validateForm = () => {
    return userEmail.length > 0 && password.length > 0;
  };

  return (
    <div>
      <Container>
        <div className="form-wrapper">
          <Form onSubmit={onSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>User</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                onChange={(e) => setUserEmail(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>

            <Button variant="primary" type="submit" disabled={!validateForm()}>
              Login
            </Button>
          </Form>
        </div>
      </Container>
    </div>
  );
};
export default Login;
