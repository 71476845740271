import React, { useEffect } from "react";
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  useRowSelect,
} from "react-table";
import { Accordion } from "react-bootstrap";

import IndeterminateCheckbox from "./IntederminateCheckBox";

import { DefaultColumnFilter, GlobalFilter } from "./Filter";

function CustomTable({
  columns,
  data,
  setRowList,
  hiddenCols,
  tableHeight,
  hideGlobalFilter,
}) {
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state,
    state: { selectedRowIds },
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    allColumns,
    getToggleHideAllColumnsProps,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { hiddenColumns: hiddenCols },
      autoResetFilters: false,
      autoResetSortBy: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",

          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  useEffect(() => {
    setRowList(selectedFlatRows.map((d) => d.original));
  }, [selectedRowIds]);

  const filterView = () => {
    if (allColumns.filter((col) => col.hidableColumn).length == 0) {
      return;
    } else {
      return (
        <Accordion>
          <Accordion.Header>Filter Columns</Accordion.Header>
          <Accordion.Body>
            <div>
              <div>
                <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} />{" "}
                Toggle All
              </div>
              {allColumns
                .filter((col) => col.hidableColumn)
                .map((column) => (
                  <div key={column.id}>
                    <label>
                      <input
                        type="checkbox"
                        {...column.getToggleHiddenProps()}
                      />{" "}
                      {column.id}
                    </label>
                  </div>
                ))}
              <br />
            </div>
          </Accordion.Body>
        </Accordion>
      );
    }
  };

  const tableStyle = {
    overflowX: "auto",
    overflow: "auto",

    display: "inline-block",
  };
  tableStyle.height = tableHeight;

  return [
    filterView(),
    <div style={tableStyle}>
      <table {...getTableProps()} className="table table-striped">
        <thead>
          <tr>
            <th
              colSpan={visibleColumns.length}
              style={{
                textAlign: "left",
              }}
            >
              {hideGlobalFilter ? (
                ""
              ) : (
                <GlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={state.globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />
              )}
            </th>
          </tr>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={{
                    position: "sticky",
                    top: 0,
                    backgroundColor: "white",
                    zIndex: 1,
                  }}
                >
                  {column.render("Header")}

                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>,
  ];
}

export default CustomTable;
