import { useState } from "react";
import axios from "axios";
import { Form, Button } from "react-bootstrap";

import authHeader from "../../services/auth-header";

const TelegramAccess = () => {
  const [telegramToken, setTelegramToken] = useState([]);
  const [telegramChatID, setTelegramChatID] = useState([]);

  const submitTelegramAccess = (event) => {
    event.preventDefault();
    let chatId;
    if (telegramChatID.length > 0) {
      chatId = telegramChatID;
    }
    axios
      .post(
        "/auth/addTelegramConnexion",
        {
          telegramChatID: chatId,
          telegramToken: telegramToken,
        },
        {
          headers: authHeader(),
        }
      )
      .then(({ data }) => {
        console.log(data);
        alert("User updated");
      })
      .catch((error) => {
        console.log(error);
        if (error.response?.data?.error == "No JWT") {
          alert("No JWT");
        }
      });
  };

  const validateTelegramAccess = () => {
    return telegramToken.length > 0;
  };

  return (
    <Form onSubmit={submitTelegramAccess}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Telegram Token</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter telegram Token"
          onChange={(e) => setTelegramToken(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Enter Chat ID</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter your chat Id"
          onChange={(e) => setTelegramChatID(e.target.value)}
        />
      </Form.Group>

      <Button
        variant="primary"
        type="submit"
        disabled={!validateTelegramAccess()}
      >
        Submit Telegram Access
      </Button>
    </Form>
  );
};

export default TelegramAccess;
