import React from "react";

import { DateTime } from "luxon";
import {
  ComposedChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
  Brush,
  ResponsiveContainer,
} from "recharts";

const ScoreChart = (props) => {
  let data = props.pl;

  console.log(data);
  const renderLineChart = (
    <ResponsiveContainer width="100%" height={400}>
      <ComposedChart
        data={data}
        fontSize={12}
        margin={{
          top: 5,
          right: 5,
          left: 15,
          bottom: 5,
        }}
      >
        <XAxis
          dataKey="day"
          domain={["dataMin", "dataMax"]}
          name="Time"
          type="number"
          tickFormatter={(date) =>
            DateTime.fromMillis(date).toFormat("MM/yyyy")
          }
        />
        <Tooltip
          labelFormatter={(date) =>
            DateTime.fromMillis(date).toFormat("dd/MM/yyyy")
          }
        />
        <CartesianGrid strokeDasharray="3 3" />
        <YAxis
          width={10}
          angle={-50}
          type="number"
          dataKey="score"
          name="score"
          domain={[0, 100]}
        />
        <Tooltip cursor={{ strokeDasharray: "3 3" }} />
        <Scatter name="Score" dataKey="score" fill="#8884d8" />
        <Brush
          dataKey="date"
          height={30}
          stroke="#8884d8"
          tickFormatter={(date) =>
            DateTime.fromISO(date).toFormat("dd/MM/yyyy")
          }
        />
      </ComposedChart>
    </ResponsiveContainer>
  );

  return renderLineChart;
};

export default ScoreChart;
