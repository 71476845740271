import React from "react";
import { DateTime } from "luxon";

import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Brush,
  AreaChart,
  Area,
  ResponsiveContainer,
} from "recharts";

const formatXAxis = (tickFormat) => {
  return DateTime.fromMillis(tickFormat).format("DD/MM/YYYY");
};

const NbCardChart = (props) => {
  let data = props.userGraph;

  const renderLineChart = (
    <ResponsiveContainer width="100%" height={350}>
      <AreaChart
        data={data}
        fontSize={12}
        margin={{
          top: 5,
          right: 0,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          tickFormatter={(date) => DateTime.fromISO(date).toFormat("MM/yyyy")}
        />
        <YAxis width={20} />

        <Tooltip
          labelFormatter={(date) =>
            DateTime.fromISO(date).toFormat("dd/MM/yyyy")
          }
        />

        <Area
          type="monotone"
          name="Nb Cards"
          stackId="3"
          dataKey="nbCard"
          stroke="green"
          fill="none"
        />
        <Brush
          tickFormatter={(date) =>
            DateTime.fromISO(date).toFormat("dd/MM/yyyy")
          }
          dataKey="date"
          height={30}
          stroke="#8884d8"
        />
      </AreaChart>
    </ResponsiveContainer>
  );

  return renderLineChart;
};

export default NbCardChart;
