import React, { useContext } from "react";
import { AuthContext } from "../Auth/auth";
import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const location = useLocation();

  const { isAuthenticated, checkAuth, isLoading, role } =
    useContext(AuthContext);

  if ((!isAuthenticated && !isLoading) || (role !== "editor" && !isLoading)) {
    return !isAuthenticated ? (
      <Navigate to="/login" replace state={{ from: location }} />
    ) : (
      <Navigate to="/home" />
    );
  }

  return children;
};
export default ProtectedRoute;
