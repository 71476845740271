import React, { useState } from "react";
import axios from "axios";

import authHeader from "../../services/auth-header.js";

import { useNavigate, useLocation } from "react-router-dom";
import {
  Row,
  Form,
  FormGroup,
  Button,
  Container,
  Col,
  Modal,
} from "react-bootstrap";

const UserSellStratForm = (props) => {
  const sellStrat = props.sellStrat;

  const [showClassicHodlCoef, setShowClassicHodlCoef] = useState(false);
  const [showStrongHodlCoef, setShowStrongHodlCoef] = useState(false);

  const [strongHodlHodlProfit, setStrongHodlHodlProfit] = useState([]);
  const [strongHodlLongTermProfit, setStrongHodlLongTermProfit] = useState([]);
  const [strongHodlMMProfit, setStrongHodlMMProfit] = useState([]);

  const [classicHodlHodlProfit, setClassicHodlHodlProfit] = useState([]);
  const [classicHodlLongTermProfit, setClassicHodlLongTermProfit] = useState(
    []
  );
  const [classicHodlMMProfit, setClassicHodlMMProfit] = useState([]);

  const editStrongHodlCoef = () => {
    setShowStrongHodlCoef(true);
  };

  const editClassicHodlCoef = () => {
    setShowClassicHodlCoef(true);
  };

  const handleSaveEditClassicCoef = (event) => {
    event.preventDefault();

    axios
      .post(
        "/sorare/setSellStratClassicHodlCoef/",
        {
          classicHodlHodlProfit: classicHodlHodlProfit,
          classicHodlLongTermProfit: classicHodlLongTermProfit,
          classicHodlMMProfit: classicHodlMMProfit,
        },
        { headers: authHeader() }
      )
      .then((res) => {
        if (res.status === 200) {
          window.location.reload(false);
        } else Promise.reject();
      })
      .catch((err) => alert("Something went wrong"));
  };

  const handleSaveEditStrongCoef = (event) => {
    event.preventDefault();
    axios
      .post(
        "/sorare/setSellStratStrongHodlCoef/",
        {
          strongHodlHodlProfit: strongHodlHodlProfit,
          strongHodlLongTermProfit: strongHodlLongTermProfit,
          strongHodlMMProfit: strongHodlMMProfit,
        },
        { headers: authHeader() }
      )
      .then((res) => {
        if (res.status === 200) {
          window.location.reload(false);
        } else Promise.reject();
      })
      .catch((err) => alert("Something went wrong"));
  };

  const handleCloseEditStrongCoef = () => {
    setShowStrongHodlCoef(false);
  };

  const handleCloseEditClassicCoef = () => {
    setShowClassicHodlCoef(false);
  };

  return [
    <Container>
      <p>
        {" "}
        <b>LT Hodl</b> : Hodl {sellStrat?.strongHodlHodlProfit} |  LongTerm{" "}
        {sellStrat?.strongHodlLongTermProfit} |  MM{" "}
        {sellStrat?.strongHodlMMProfit}  
        <Button variant="outline-danger" onClick={editStrongHodlCoef}>
          Edit
        </Button>
      </p>
      <p>
        {" "}
        <b>MT Hodl</b> : Hodl {sellStrat?.classicHodlHodlProfit} |  LongTerm{" "}
        {sellStrat?.classicHodlLongTermProfit} |  MM{" "}
        {sellStrat?.classicHodlMMProfit}  
        <Button variant="outline-danger" onClick={editClassicHodlCoef}>
          Edit
        </Button>
      </p>
    </Container>,
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={showStrongHodlCoef}
      onHide={handleCloseEditStrongCoef}
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit LT Hodl Coef</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSaveEditStrongCoef}>
          <Form.Group className="mb-3">
            <Form.Label>Hodl</Form.Label>

            <Form.Control
              type="number"
              step="0.1"
              min="0"
              placeholder="Set Long Term Coef"
              defaultValue={parseFloat(sellStrat?.strongHodlHodlProfit)}
              onChange={(e) => setStrongHodlHodlProfit(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Long Term</Form.Label>

            <Form.Control
              type="number"
              step="0.1"
              min="0"
              placeholder="Set Long Term Coef"
              defaultValue={parseFloat(sellStrat?.strongHodlLongTermProfit)}
              onChange={(e) => setStrongHodlLongTermProfit(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>MM</Form.Label>

            <Form.Control
              type="number"
              step="0.1"
              min="0"
              placeholder="Set MM Coef"
              defaultValue={parseFloat(sellStrat?.strongHodlMMProfit)}
              onChange={(e) => setStrongHodlMMProfit(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasic">
            <Button variant="outline-success" type="submit">
              Submit
            </Button>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>,
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={showClassicHodlCoef}
      onHide={handleCloseEditClassicCoef}
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit MT Hodl Coef</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSaveEditClassicCoef}>
          <Form.Group className="mb-3">
            <Form.Label>Hodl</Form.Label>

            <Form.Control
              type="number"
              step="0.1"
              min="0"
              placeholder="Set Long Term Coef"
              defaultValue={sellStrat?.classicHodlHodlProfit}
              onChange={(e) => setClassicHodlHodlProfit(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Long Term</Form.Label>

            <Form.Control
              type="number"
              step="0.1"
              min="0"
              placeholder="Set Long Term Coef"
              defaultValue={sellStrat?.classicHodlLongTermProfit}
              onChange={(e) => setClassicHodlLongTermProfit(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>MM</Form.Label>

            <Form.Control
              type="number"
              step="0.1"
              min="0"
              placeholder="Set MM Coef"
              defaultValue={sellStrat?.classicHodlMMProfit}
              onChange={(e) => setClassicHodlMMProfit(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasic">
            <Button variant="outline-success" type="submit">
              Submit
            </Button>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>,
  ];
};

export default UserSellStratForm;
