import React, { useState } from "react";
import { DateTime } from "luxon";

import authHeader from "../../services/auth-header";
import axios from "axios";

import Select from "react-select";

import { Row, Col, Container, Spinner, Button } from "react-bootstrap";

import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  AreaChart,
  Area,
  Brush,
  ResponsiveContainer,
} from "recharts";

const randomColor = () => {
  let color = "#";
  for (let i = 0; i < 6; i++) {
    const random = Math.random();
    const bit = (random * 16) | 0;
    color += bit.toString(16);
  }
  return color;
};

const HistoEmissionChart = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedLeagues, setSelectedLeagues] = useState([]);
  const [selectedGraph, setSelectedGraph] = useState([
    { value: "Volume", stackId: "1", yAxisId: "left" },
  ]);
  const [histoEmission, setHistoEmission] = useState([]);

  const getHistoEmission = () => {
    setIsLoading(true);
    axios
      .post(
        "/sorare/getHistoEmission",
        { selectedLeagues: selectedLeagues },
        { headers: authHeader() }
      )
      .then(({ data }) => {
        console.log(data);
        setIsLoading(false);
        setHistoEmission(data.histoEmission);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const graphOptions = [
    { value: "Volume", stackId: "1", yAxisId: "left" },
    { value: "Number", stackId: "2", yAxisId: "right" },
  ];

  const leagueOptions = [
    {
      slug: "ligue-1-fr",
      label: "Ligue 1",
      VolumeColor: "#6495ed",
      NumberColor: "#00008b",
    },
    {
      slug: "serie-a-it",
      label: "Serie A",
      VolumeColor: "red",
      NumberColor: "#8b0000",
    },
    {
      slug: "bundesliga-de",
      label: "BundesLiga",
      VolumeColor: "#9370db",
      NumberColor: "#663399",
    },
    {
      slug: "premier-league-gb-eng",
      label: "Premier League",
      VolumeColor: "#8fbc8f",
      NumberColor: "#228b22",
    },
    {
      slug: "laliga-santander",
      label: "Liga",
      VolumeColor: "#ffa500",
      NumberColor: "#ff8c00",
    },
  ];

  const onchangeSelectLeague = (items) => {
    setSelectedLeagues(items);
  };
  const onchangeSelectGraph = (items) => {
    setSelectedGraph(items);
  };

  const renderLineChart = (
    <Container>
      <Row>
        <Col sm={9}>
          <Select
            options={leagueOptions}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            onChange={onchangeSelectLeague}
            getOptionValue={(option) => option.slug}
            getOptionLabel={(option) => option.label}
            styles={{ menu: (styles) => ({ ...styles, zIndex: 999 }) }}
          />
        </Col>

        <Col sm={3}>
          {isLoading ? (
            <Spinner animation="border" />
          ) : (
            <Button
              variant="outline-success"
              type="submit"
              onClick={getHistoEmission}
            >
              Submit
            </Button>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <Select
            options={graphOptions}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            defaultValue={[graphOptions[0]]}
            onChange={onchangeSelectGraph}
            getOptionValue={(option) => option.value}
            getOptionLabel={(option) => option.value}
            styles={{ menu: (styles) => ({ ...styles, zIndex: 999 }) }}
          />
        </Col>
      </Row>
      {histoEmission.length !== 0 ? (
        <ResponsiveContainer width="100%" height={350}>
          <AreaChart
            data={histoEmission}
            fontSize={12}
            paddingLeft={0}
            margin={{
              top: 5,
              right: 5,
              left: 15,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              tickFormatter={(date) =>
                DateTime.fromISO(date).toFormat("MM/yyyy")
              }
            />
            <YAxis yAxisId="left" width={20} angle={-50} />
            <YAxis yAxisId="right" orientation="right" width={20} angle={-50} />

            <Tooltip
              labelFormatter={(date) =>
                DateTime.fromISO(date).toFormat("dd/MM/yyyy")
              }
            />
            {selectedGraph.map((graph) => {
              return selectedLeagues.map((league) => {
                return (
                  <Area
                    yAxisId={graph.yAxisId}
                    type="monotone"
                    name={graph.value + " Emission " + league.slug}
                    stackId={graph.stackId}
                    dataKey={league.slug + graph.value + "Auctions"}
                    stroke={league[graph.value + "Color"]}
                    fill={league[graph.value + "Color"]}
                  />
                );
              });
            })}

            <Brush
              dataKey="date"
              height={30}
              stroke="#8884d8"
              tickFormatter={(date) =>
                DateTime.fromISO(date).toFormat("dd/MM/yyyy")
              }
            />
          </AreaChart>
        </ResponsiveContainer>
      ) : (
        ""
      )}
    </Container>
  );
  return renderLineChart;
};

export default HistoEmissionChart;
