import React, { useState, useEffect, useMemo, useContext } from "react";
import { AuthContext } from "../Auth/auth";
import axios from "axios";
import { Col, Row, Badge, Modal, Form, Button, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";

import CustomTable from "../Tables/Table.js";
import { DateTime } from "luxon";
import authHeader from "../../services/auth-header.js";
import RefreshCards from "../Actions/refreshCards.js";

const UserCards = () => {
  const { isAuthenticated, checkAuth, isLoading, role } =
    useContext(AuthContext);
  const [cardList, setCardList] = useState([]);
  const [lastUpdate, setLastUpdate] = useState("");

  const [sellCard, setSellCard] = useState([]);
  const [showSell, setShowSell] = useState(false);
  const [receiveEth, setReceiveEth] = useState("default");

  const [rowList, setRowList] = useState({});

  useEffect(() => {
    // Retrieve user info from sorare API
    axios
      .get("/sorare/getUserCards/", { headers: authHeader() })
      .then(({ data }) => {
        console.log(data);
        setCardList(data.cardsList);
        setLastUpdate(data.lastUpdate);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleCloseSell = () => setShowSell(false);
  const handleShowSell = () => {
    setShowSell(true);
  };
  const handleSellCard = (event) => {
    event.preventDefault();
    setShowSell(false);
    axios
      .post(
        "/sorare/sellCard",
        {
          cardSlug: sellCard.slug,
          rarity: sellCard.rarity,
          receiveEth: receiveEth,
        },
        { headers: authHeader() }
      )
      .then(({ data }) => {
        console.log(data);
        setTimeout(() => handleRefresh("fastRefreshCardsList"), 3000);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleCancelSale = (slug) => {
    axios
      .post(
        "/sorare/cancelSale",
        {
          cardSlug: slug,
        },
        { headers: authHeader() }
      )
      .then(({ data }) => {
        console.log(data);
        setTimeout(() => handleRefresh("fastRefreshCardsList"), 2000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleRefresh = (e) => {
    axios
      .get("/sorare/" + e, { headers: authHeader() })
      .then(({ data }) => {
        alert("Cards List refreshed for : " + data.cardsList.length + " cards");
        setCardList(data.cardsList);
        setLastUpdate(data.lastUpdate);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const columns = useMemo(
    () => [
      {
        // first group - Player info
        Header: "Card",

        columns: [
          {
            accessor: "pictureUrl",
            disableFilters: true,
            Cell: ({ cell: { value } }) => (
              <div className="blog-comments__avatar mr-3">
                <img src={value} alt={value} className="card" />
              </div>
            ),
          },
          {
            Header: "Name",
            accessor: "name",
            Cell: (props) => {
              return (
                <Link
                  to={
                    "/player/" +
                    props.row.original.playerId +
                    "/" +
                    props.row.original.rarity
                  }
                >
                  {props.value}
                </Link>
              );
            },
          },
          {
            Header: "PriceRecap",
            disableFilters: true,
            Cell: (props) => {
              return [
                <p>P: {props.row.original.purchasePrice}</p>,

                <p
                  style={
                    props.row.original.stats?.month3?.avgPrice5Trans -
                      props.row.original.purchasePrice >
                    0
                      ? { color: "green" }
                      : { color: "red" }
                  }
                >
                  M: {props.row.original.stats?.month3?.avgPrice5Trans}
                </p>,
                <p>L: {props.row.original.stats?.month6?.lastPrice}</p>,
                <p>
                  S{" "}
                  {props.row.original.liveSingleSaleOffer
                    ? (
                        props.row.original.liveSingleSaleOffer.price /
                        10 ** 18
                      ).toFixed(4)
                    : ""}
                </p>,

                <p>
                  F:{" "}
                  {(
                    props.row.original.Player.FloorPrice?.[
                      props.row.original.rarity
                    ].wei /
                    10 ** 18
                  ).toFixed(4)}
                </p>,
              ];
            },
          },
        ],
      },
      {
        Header: "Purchase ",

        columns: [
          {
            Header: "Bought On",
            accessor: "boughtOn",
            disableFilters: true,
            Cell: (props) => DateTime.fromISO(props.value).toLocaleString(),
          },
          {
            Header: "Purchase Price",
            disableFilters: true,

            accessor: "purchasePrice",
          },
          {
            Header: "Purchase Price Eur",
            disableFilters: true,

            accessor: "purchasePriceEur",
          },
        ],
      },
      {
        Header: "Market",
        columns: [
          {
            Header: "Floor Price",
            disableFilters: true,

            accessor: "Player.FloorPrice",
            Cell: (props) => {
              return props.value
                ? (
                    props.value[props.row.original.rarity].wei /
                    10 ** 18
                  ).toFixed(4)
                : "";
            },
          },
          {
            Header: "+/- Value vs flr",
            disableFilters: true,

            accessor: "pmvaluevsfloor",
            display: (props) => {
              return props.value !== "NaN" ? props.value + "%" : "";
            },
          },
          {
            Header: "Market Price",
            disableFilters: true,

            accessor: "stats.month3.avgPrice5Trans",
          },
          {
            Header: "+/- Value",
            disableFilters: true,

            accessor: "pmvalue",
            sortBy: "basic",
            Cell: (props) => {
              return props.value !== "NaN" ? props.value + "%" : "";
            },
          },
          {
            Header: "Last Price",
            disableFilters: true,

            accessor: "stats.month6.lastPrice",
          },

          {
            Header: "+/- Value vs Last",
            disableFilters: true,

            accessor: "pmvaluevslast",
            sortBy: "basic",
            Cell: (props) => {
              return props.value !== "NaN" ? props.value + "%" : "";
            },
          },

          {
            Header: "On Sale",
            disableFilters: true,

            accessor: "liveSingleSaleOffer.price",
            Cell: (props) => {
              return props.value ? (
                <div>
                  <p>{(props.value / 10 ** 18).toFixed(4)}</p>
                  <Button
                    size="sm"
                    variant="outline-danger"
                    onClick={() => {
                      console.log(props.row.original);
                      handleCancelSale(props.row.original.slug);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              ) : (
                ""
              );
            },
          },
          {
            Header: "Sell Card",
            disableFilters: true,

            accessor: "actions",
            Cell: (props) => {
              return (
                <div>
                  <Button
                    size="sm"
                    variant="outline-danger"
                    onClick={() => {
                      console.log(props.row.original);
                      handleShowSell();
                      setSellCard(props.row.original);
                    }}
                  >
                    {" "}
                    Sell
                  </Button>
                </div>
              );
            },
          },
        ],
      },
      {
        Header: "Stategy",

        columns: [
          {
            Header: "Buy Strat",
            accessor: "buyStrategy",
          },
          {
            Header: "Sell Strat",
            accessor: "sellStrategy",
          },
        ],
      },
    ],
    [lastUpdate]
  );
  return isLoading ? (
    <Spinner animation="border" />
  ) : (
    <div>
      <Row>
        <Col style={{ textAlign: "left" }}>
          {" "}
          <Badge bg="light" text="dark" style={{ fontSize: 15 }}>
            Updated: {new Date(lastUpdate).toLocaleString("fr-FR")}
          </Badge>
        </Col>

        <Col style={{ textAlign: "right", marginBottom: 10, fontSize: 15 }}>
          <RefreshCards
            setCardList={setCardList}
            setLastUpdate={setLastUpdate}
          />
        </Col>
      </Row>

      <Row>
        <CustomTable
          columns={columns}
          data={cardList}
          setRowList={setRowList}
          hiddenCols={[]}
          tableHeight="90vh"
        />
      </Row>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showSell}
        onHide={handleCloseSell}
      >
        <Modal.Header closeButton>
          <Modal.Title>Sell - {sellCard.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Purchase price: {sellCard.purchasePrice} </p>
          <p>Purchase Price Eur: {sellCard.purchasePriceEur} </p>
          <p>-------</p>
          <p>
            FloorPrice Last Update:{" "}
            {new Date(sellCard.floorLastUpdate).toLocaleString("fr-FR")}{" "}
          </p>
          <p>FloorPrice: {(sellCard.floor?.wei / 10 ** 18).toFixed(4)} </p>
          <p>FloorPrice Eur: {sellCard.floor?.eur.toFixed(2)} € </p>
          <Form onSubmit={handleSellCard}>
            <Form.Group className="mb-3" controlId="formBasic">
              <Form.Label> Price </Form.Label>
              <Form.Control
                type="number"
                step="0.0001"
                min="0"
                placeholder="Set Selling Price"
                onChange={(e) => setReceiveEth(e.target.value)}
              />
              <Button variant="outline-danger" type="submit">
                Sell
              </Button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UserCards;
