import React, { useState, useEffect, useMemo, useContext } from "react";
import { AuthContext } from "../Auth/auth";
import axios from "axios";
import {
  Table,
  Row,
  Col,
  Badge,
  Button,
  Dropdown,
  DropdownButton,
  Spinner,
} from "react-bootstrap";

import authHeader from "../../services/auth-header";

import CustomTable from "../Tables/Table";

const UserHist = () => {
  const { isAuthenticated, checkAuth, isLoading, role } =
    useContext(AuthContext);
  const [userHist, setUserHist] = useState([]);
  const [lastUpdate, setLastUpdate] = useState("");
  const [rowList, setRowList] = useState({});
  const [isDataLoading, setIsDataLoading] = useState(false);

  useEffect(() => {
    axios
      .get("/sorare/getUserHistory", { headers: authHeader() })
      .then(({ data }) => {
        console.log(data);
        setUserHist(data.accountEntriesList);
        setLastUpdate(data.lastUpdate);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const updateHistory = () => {
    setIsDataLoading(true);
    axios
      .get("/sorare/updateHistory/", { headers: authHeader() })
      .then(({ data }) => {
        setIsDataLoading(false);
        setUserHist(data.accountEntriesList);
        setLastUpdate(data.lastUpdate);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const columns = useMemo(
    () => [
      {
        // first group - Player info
        Header: "Entry",

        columns: [
          {
            Header: "Date",
            disableFilters: true,

            accessor: "date",
            Cell: (props) => {
              return new Date(props.value).toLocaleString("FR-fr");
            },
          },
          {
            Header: "type",
            accessor: "type",
          },
          {
            Header: "Amount",
            disableFilters: true,
            accessor: "amount",
          },
          {
            Header: "Amount Eur",
            disableFilters: true,
            accessor: "amountFiat",
          },
        ],
      },
      {
        Header: "Cards",

        columns: [
          {
            Header: "Cards Received",
            accessor: "cardsReceived",
          },
          {
            Header: "Cards Sent",
            accessor: "cardsSent",
          },
          {
            Header: "Profit",
            disableFilters: true,
            accessor: "profit",
          },
          {
            Header: "Profit Eur",
            disableFilters: true,
            accessor: "profitEUR",
          },
          {
            Header: "Kept For",
            accessor: "kept",
          },
        ],
      },
    ],
    [userHist]
  );

  return isLoading ? (
    <Spinner animation="border" />
  ) : (
    <div>
      <Row>
        {" "}
        <Col style={{ textAlign: "left" }}>
          {" "}
          <Badge
            bg="light"
            text="dark"
            style={{ marginBottom: 10, fontSize: 15 }}
          >
            Updated: {new Date(lastUpdate).toLocaleString("fr-FR")}
          </Badge>
        </Col>
        <Col style={{ textAlign: "right" }}>
          {" "}
          {isDataLoading ? (
            <Spinner animation="border" />
          ) : (
            <Button
              style={{ marginBottom: 10, fontSize: 15 }}
              variant="outline-warning"
              onClick={updateHistory}
            >
              Refresh
            </Button>
          )}
        </Col>
      </Row>
      <Row>
        <CustomTable
          columns={columns}
          data={userHist}
          setRowList={setRowList}
          hiddenCols={[]}
          tableHeight="90vh"
        />
      </Row>
    </div>
  );
};

export default UserHist;
