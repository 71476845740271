import { Col } from "react-bootstrap";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import authHeader from "../../services/auth-header";
import axios from "axios";
import React, { useState } from "react";

const SetMaxBuyCoefSafety = (props) => {
  const { maxBuyCoef, setMaxBuyCoef, setReload, reload } = { ...props };

  const setCoef = (coef) => {
    setMaxBuyCoef(coef);
    axios
      .put("/sorare/setMaxBuyCoef", { coef: coef }, { headers: authHeader() })
      .then(() => {
        if (setReload) {
          setReload(reload + 1);
        }
      })
      .catch((error) => {
        alert("Something went wrong");
        console.log(error);
      });
  };
  return (
    <Col>
      <DropdownButton
        variant="outline-warning"
        id="select-strategy"
        onSelect={setCoef}
        title={"Sell Max Buy Safety Coefficient:" + maxBuyCoef}
      >
        <Dropdown.Item eventKey="0"> 0</Dropdown.Item>
        <Dropdown.Item eventKey="0.1"> 0.1</Dropdown.Item>
        <Dropdown.Item eventKey="0.25"> 0.25</Dropdown.Item>
        <Dropdown.Item eventKey="0.5"> 0.5</Dropdown.Item>
        <Dropdown.Item eventKey="0.75"> 0.75</Dropdown.Item>
        <Dropdown.Item eventKey="1"> 1</Dropdown.Item>
      </DropdownButton>
    </Col>
  );
};
export default SetMaxBuyCoefSafety;
