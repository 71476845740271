import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Button, Modal, Form } from "react-bootstrap";
import axios from "axios";

import authHeader from "../../services/auth-header.js";

const DeleteStrategy = (props) => {
  const {
    deleteRow,
    showDelete,
    setShowDelete,
    strats,
    setStrats,
    refresh,
    setRefresh,
    competition,
  } = { ...props };

  const handleDeleteStrategy = () => {
    let index = strats.findIndex(
      (element) =>
        element.id == deleteRow.id && element.rarity == deleteRow.rarity
    );
    let newStrats = [...strats];
    newStrats[index].published = "Pending";
    setStrats(newStrats);
    setRefresh(refresh + 1);
    setShowDelete(false);

    axios
      .post(
        "/sorare/deletePlayerStrategy/",
        { id: deleteRow.id, rarity: deleteRow.rarity },
        { headers: authHeader() }
      )
      .then(({ data }) => {
        console.log(data);
        if (data.deleted) {
          console.log("deleted");
          let newStrats = [...strats];
          if (competition) {
            newStrats[index].strategy = null;
          } else {
            newStrats = strats.filter(
              (strategy) =>
                !(
                  strategy.id == deleteRow.id &&
                  strategy.rarity == deleteRow.rarity &&
                  strategy.userId == deleteRow.userId
                )
            );
          }
          setStrats(newStrats);
          setRefresh(refresh + 1);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCloseDelete = () => {
    setShowDelete(false);
  };

  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={showDelete}
      onHide={handleCloseDelete}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {deleteRow.displayName} - {deleteRow.rarity}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete this strategy ?
        <p style={{ textAlign: "right" }}>
          {" "}
          <Button
            variant="outline-danger"
            type="submit"
            onClick={handleDeleteStrategy}
          >
            Yes
          </Button>
        </p>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteStrategy;
