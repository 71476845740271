import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Button, Modal, Form } from "react-bootstrap";
import axios from "axios";

import authHeader from "../../services/auth-header.js";

const CopyStrategiesToRarity = (props) => {
  const navigate = useNavigate();

  const rowList = props.rowList;
  const [buyStrategy, setBuyStrategy] = useState("none");
  const [sellStrategy, setSellStrategy] = useState("none");
  const [safetyBid, setSafetyBid] = useState("");
  const [budget, setBudget] = useState("");
  const [rarity, setRarity] = useState("");
  const [sellAtPerc, setSellAtPerc] = useState("default");

  const [showEditBulk, setShowEditBulk] = useState(false);

  const validateForm = () => {
    if (rowList.length == 0 || rarity == "") {
      return false;
    } else return true;
  };

  const handleShowEditBulk = () => {
    setShowEditBulk(true);
  };
  const handleSaveEditBulk = (event) => {
    event.preventDefault();
    setShowEditBulk(false);
    if (rowList.length == 0) {
      return;
    }
    let playerList = [];

    if (rarity !== "default") {
      rowList.map((row) =>
        playerList.push({ playerId: row.id, rarity: rarity })
      );
    }
    let strats = {
      playerList: playerList,
      buyStrategy: buyStrategy,
      sellStrategy: sellStrategy,
    };

    if (safetyBid !== "") {
      strats.safetyBid = safetyBid;
    }
    if (budget !== "") {
      strats.budget = budget;
    }

    axios
      .post("/sorare/bulkEditPlayerStrategy/", strats, {
        headers: authHeader(),
      })
      .then(({ data }) => {
        console.log(data);
        if (data.updated) {
          console.log("published");
          navigate(0);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCloseEditBulk = () => {
    setShowEditBulk(false);
  };
  return [
    <Col>
      <Button
        variant="outline-danger"
        type="submit"
        onClick={handleShowEditBulk}
      >
        Copy Selected strategies to other Rarity
      </Button>
    </Col>,
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={showEditBulk}
      onHide={handleCloseEditBulk}
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Strategies</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {rowList.map((row) => (
          <p>
            {" "}
            {row.displayName} - {row.rarity}{" "}
          </p>
        ))}

        <Form onSubmit={handleSaveEditBulk}>
          <Form.Group className="mb-3">
            <Form.Label>Rarity</Form.Label>
            <Form.Select
              defaultValue={rarity}
              onChange={(e) => setRarity(e.target.value)}
            >
              <option value=""></option>
              <option value="limited">Limited</option>
              <option value="rare">Rare</option>
              <option value="super_rare"> Super Rare </option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Buy Strategy</Form.Label>
            <Form.Select
              defaultValue={"default"}
              onChange={(e) => setBuyStrategy(e.target.value)}
            >
              <option value=""></option>
              <option value="autoBid">Buy @ Auto Bid</option>
              <option value="autoBidMM">Buy @ Auto Bid MM </option>{" "}
              <option value="manualBid">Buy @ Manual Bid</option>
              <option value="none"> Do not buy </option>
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Sell Strategy</Form.Label>
            <Form.Select
              defaultValue={"default"}
              onChange={(e) => setSellStrategy(e.target.value)}
            >
              <option value=""></option>
              <option value="classicHodl">MT Hodl</option>
              <option value="strongHodl">LT Hodl</option>
              <option value="sellAllAtPerc">Sell All @ Profit%</option>
              <option value="sell1AtPerc">Sell 1 @ Profit%</option>
              <option value="sellAllAtTarget">Sell All @ Target</option>
              <option value="sell1AtTarget">Sell 1 @ Target</option>
              <option value="none"> None</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasic">
            <Form.Label> Budget </Form.Label>
            <Form.Control
              type="number"
              step="0.0001"
              min="0"
              placeholder="Set Budget"
              defaultValue="default"
              onChange={(e) => setBudget(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasic">
            <Form.Label> Safety Bid</Form.Label>
            <Form.Control
              type="number"
              step="0.0001"
              min="0"
              placeholder="Set Safety Bid"
              defaultValue="default"
              onChange={(e) => setSafetyBid(e.target.value)}
            />
            <Button
              variant="outline-success"
              type="submit"
              disabled={!validateForm()}
            >
              Submit
            </Button>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>,
  ];
};

export default CopyStrategiesToRarity;
