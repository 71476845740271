import React, { useState, useEffect } from "react";
import {
  Nav,
  Navbar,
  NavDropdown,
  Container,
  Button,
  Alert,
} from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../Auth/auth";

const Navigation = () => {
  const { userName, isAuthenticated, isLoading, logout, role } =
    React.useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();

  if (!isAuthenticated) {
    return (
      <header className="App-header">
        <Navbar bg="dark" variant="dark" sticky="top">
          <Container>
            <Navbar.Brand>
              <Link to={"/home"} className="nav-link">
                botSorare
              </Link>
            </Navbar.Brand>
            <Nav className="me-auto">
              <Link to={"/signup"} className="nav-link">
                Sign Up
              </Link>
            </Nav>

            <Nav className="justify-content-end">
              <Nav>
                <Link to={"/login"} className="nav-link">
                  Login
                </Link>
              </Nav>
            </Nav>
          </Container>
        </Navbar>
      </header>
    );
  }

  if (isAuthenticated) {
    if (role === "editor") {
      return (
        <header className="App-header">
          <Navbar
            collapseOnSelect
            expand="lg"
            bg="dark"
            variant="dark"
            sticky="top"
          >
            <Container>
              <Navbar.Brand>
                <Link to={"/home"} className="nav-link">
                  botSorare
                </Link>
              </Navbar.Brand>

              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse>
                <Nav className="me-auto">
                  <Link to={"/admin"} className="nav-link">
                    Admin - {userName}
                  </Link>
                </Nav>

                <Nav className="justify-content-end">
                  <Nav>
                    <Link to={"/user-stats/"} className="nav-link">
                      Stats
                    </Link>
                  </Nav>
                  <Nav>
                    <Link to={"/user-history/"} className="nav-link">
                      History
                    </Link>
                  </Nav>

                  <Nav>
                    <Link to={"/user-cards/"} className="nav-link">
                      Cards
                    </Link>
                  </Nav>

                  <Nav>
                    <Link to={"/search-player/"} className="nav-link">
                      Search Player
                    </Link>
                  </Nav>
                  <Nav>
                    <Link to={"/strategy-list/"} className="nav-link">
                      Strategies
                    </Link>
                  </Nav>
                  <Nav>
                    <Link to={"/competition-list/"} className="nav-link">
                      League Players
                    </Link>
                  </Nav>
                  <Nav>
                    <Button
                      variant="link"
                      onClick={logout}
                      className="nav-link"
                    >
                      Logout
                    </Button>
                  </Nav>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </header>
      );
    } else if (role === "scout") {
      return (
        <header className="App-header">
          <Navbar
            collapseOnSelect
            expand="lg"
            bg="dark"
            variant="dark"
            sticky="top"
          >
            <Container>
              <Navbar.Brand>
                <Link to={"/home"} className="nav-link">
                  botSorare
                </Link>
              </Navbar.Brand>

              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse>
                <Nav className="justify-content-end">
                  <Nav>
                    <Link to={"/search-player/"} className="nav-link">
                      Search Player
                    </Link>
                  </Nav>

                  <Nav>
                    <Link to={"/competition-list/"} className="nav-link">
                      League Players
                    </Link>
                  </Nav>
                  <Nav>
                    <Button
                      variant="link"
                      onClick={logout}
                      className="nav-link"
                    >
                      Logout
                    </Button>
                  </Nav>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </header>
      );
    } else if (role === "viewer") {
      return (
        <header className="App-header">
          <Navbar
            collapseOnSelect
            expand="lg"
            bg="dark"
            variant="dark"
            sticky="top"
          >
            <Container>
              <Navbar.Brand>
                <Link to={"/home"} className="nav-link">
                  botSorare
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse>
                <Nav className="justify-content-end">
                  <Nav>
                    <Link to={"/user-stats/"} className="nav-link">
                      Stats
                    </Link>
                  </Nav>
                  <Nav>
                    <Button
                      variant="link"
                      onClick={logout}
                      className="nav-link"
                    >
                      Logout
                    </Button>
                  </Nav>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </header>
      );
    } else {
      return (
        <header className="App-header">
          <Navbar
            collapseOnSelect
            expand="lg"
            bg="dark"
            variant="dark"
            sticky="top"
          >
            <Container>
              <Navbar.Brand>
                <Link to={"/home"} className="nav-link">
                  botSorare
                </Link>
              </Navbar.Brand>
              <Nav>
                <Button variant="link" onClick={logout} className="nav-link">
                  Logout
                </Button>
              </Nav>
            </Container>
          </Navbar>
        </header>
      );
    }
  }
};

export default Navigation;
