import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Button, Modal, Form } from "react-bootstrap";
import axios from "axios";

import authHeader from "../../services/auth-header.js";

const StrategiesBulkEdit = (props) => {
  const navigate = useNavigate();

  const rowList = props.rowList;
  const [buyStrategy, setBuyStrategy] = useState("default");
  const [sellStrategy, setSellStrategy] = useState("default");
  const [safetyBid, setSafetyBid] = useState("");
  const [budget, setBudget] = useState("");
  const [sellAtPerc, setSellAtPerc] = useState("default");

  const [showEditBulk, setShowEditBulk] = useState(false);

  const validateForm = () => {
    if (rowList.length == 0) {
      return false;
    } else return true;
  };

  const handleShowEditBulk = () => {
    setShowEditBulk(true);
  };
  const handleSaveEditBulk = (event) => {
    event.preventDefault();
    setShowEditBulk(false);
    if (rowList.length == 0) {
      return;
    }
    let playerList = [];

    rowList.map((row) =>
      playerList.push({ playerId: row.id, rarity: row.rarity })
    );

    let strats = {
      playerList: playerList,
    };

    if (buyStrategy !== "default") {
      strats.buyStrategy = buyStrategy;
    }
    if (sellStrategy !== "default") {
      strats.sellStrategy = sellStrategy;
    }
    if (sellAtPerc !== "default") {
      strats.sellAtPerc = sellAtPerc;
    }

    if (safetyBid !== "") {
      strats.safetyBid = safetyBid;
    }
    if (budget !== "") {
      strats.budget = budget;
    }

    axios
      .post("/sorare/bulkEditPlayerStrategy/", strats, {
        headers: authHeader(),
      })
      .then(({ data }) => {
        console.log(data);
        if (data.updated) {
          console.log("published");
          navigate(0);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    setSafetyBid("");
    setBudget("");
    setBuyStrategy("default");
    setSellStrategy("default");
    setSellAtPerc("default");
  };

  const handleCloseEditBulk = () => {
    setShowEditBulk(false);
    setSafetyBid("");
    setBudget("");
    setBuyStrategy("default");
    setSellStrategy("default");
    setSellAtPerc("default");
  };
  return [
    <Col>
      <Button
        variant="outline-danger"
        type="submit"
        onClick={handleShowEditBulk}
      >
        Edit Selected Strategies
      </Button>
    </Col>,
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={showEditBulk}
      onHide={handleCloseEditBulk}
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Strategies</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {rowList.map((row) => (
          <p>
            {" "}
            {row.displayName} - {row.rarity}{" "}
          </p>
        ))}

        <Form onSubmit={handleSaveEditBulk}>
          <Form.Group className="mb-3">
            <Form.Label>Buy Strategy</Form.Label>
            <Form.Select
              defaultValue={"default"}
              onChange={(e) => setBuyStrategy(e.target.value)}
            >
              {" "}
              <option value="default"> </option>
              <option value="autoBid">Buy @ Auto Bid</option>
              <option value="autoBidMM">Buy @ Auto Bid MM </option>
              <option value="manualBid">Buy @ Manual Bid</option>
              <option value="none"> Do not buy </option>
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Sell Strategy</Form.Label>
            <Form.Select
              defaultValue={"default"}
              onChange={(e) => setSellStrategy(e.target.value)}
            >
              <option value="default"> </option>
              <option value="classicHodl">MT Hodl</option>
              <option value="strongHodl">LT Hodl</option>
              <option value="sellAllAtPerc">Sell All @ Profit%</option>
              <option value="sell1AtPerc">Sell 1 @ Profit%</option>
              <option value="sellAllAtTarget">Sell All @ Target</option>
              <option value="sell1AtTarget">Sell 1 @ Target</option>
              <option value="none"> None</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Sell At Percentage</Form.Label>
            <Form.Select
              defaultValue={"default"}
              onChange={(e) => setSellAtPerc(e.target.value)}
            >
              <option value="default"></option>
              <option value="1">0%</option>
              <option value="1.1">10%</option>
              <option value="1.2">20%</option>
              <option value="1.3">30%</option>
              <option value="1.4"> 40%</option>
              <option value="1.75"> 75%</option>
              <option value="2"> 100%</option>
              <option value="3"> 200%</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasic">
            <Form.Label> Budget </Form.Label>
            <Form.Control
              type="number"
              step="0.0001"
              min="0"
              placeholder="Set Budget"
              defaultValue=""
              onChange={(e) => setBudget(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasic">
            <Form.Label> Safety Bid</Form.Label>
            <Form.Control
              type="number"
              step="0.0001"
              min="0"
              placeholder="Set Safety Bid"
              defaultValue=""
              onChange={(e) => setSafetyBid(e.target.value)}
            />
            <Button
              variant="outline-success"
              type="submit"
              disabled={!validateForm()}
            >
              Submit
            </Button>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>,
  ];
};

export default StrategiesBulkEdit;
