import React, { useState } from "react";

import { Dropdown, DropdownButton, Spinner } from "react-bootstrap";
import axios from "axios";

import authHeader from "../../services/auth-header.js";

const RefreshCards = (props) => {
  const { subTitle, setCardList, setLastUpdate } = { ...props };
  const [isDataRefreshing, setIsDataRefreshing] = useState(false);
  const handleRefresh = (e) => {
    setIsDataRefreshing(true);
    axios
      .get("/sorare/" + e, { headers: authHeader() })
      .then(({ data }) => {
        setIsDataRefreshing(false);
        setCardList(data.cardsList);
        setLastUpdate(data.lastUpdate);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return isDataRefreshing ? (
    <Spinner animation="border" className="justify-content-end" />
  ) : (
    <DropdownButton
      variant="outline-warning"
      onSelect={handleRefresh}
      title={subTitle ? "Refresh " + subTitle : "Refresh"}
      id="sortByScroll"
      className="justify-content-end"
    >
      <Dropdown.Item eventKey={"fastRefreshCardsList"}>
        Fast Refresh{" "}
      </Dropdown.Item>
      <Dropdown.Item eventKey={"updateCardsList"}>
        Refresh Prices, Stats and FloorPrices{" "}
      </Dropdown.Item>
      <Dropdown.Item eventKey={"refreshCardsListFloorPrice"}>
        Refresh FloorPrices only{" "}
      </Dropdown.Item>
      <Dropdown.Item eventKey={"refreshUsedBudget"}>
        Refresh Used Budget only{" "}
      </Dropdown.Item>
    </DropdownButton>
  );
};

export default RefreshCards;
