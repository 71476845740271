import { useState } from "react";
import axios from "axios";
import { Form, Button } from "react-bootstrap";

import authHeader from "../../services/auth-header";

const APIKey = () => {
  const [apiKey, setApiKey] = useState([]);

  const submitApiKey = (event) => {
    event.preventDefault();
    axios
      .post(
        "/auth/addApiKey",
        {
          apiKey: apiKey,
        },
        {
          headers: authHeader(),
        }
      )
      .then(({ data }) => {
        console.log(data);
        alert("User updated");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validateApiKey = () => {
    return apiKey.length > 0;
  };

  return (
    <Form onSubmit={submitApiKey}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>API Key</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter API Key"
          onChange={(e) => setApiKey(e.target.value)}
        />
      </Form.Group>

      <Button variant="primary" type="submit" disabled={!validateApiKey()}>
        Submit API Key
      </Button>
    </Form>
  );
};

export default APIKey;
