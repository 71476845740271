import React from "react";
import { DateTime } from "luxon";

import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  AreaChart,
  Area,
  Brush,
  ResponsiveContainer,
} from "recharts";

import { Row, Col } from "react-bootstrap";

const randomColor = () => {
  let color = "#";
  for (let i = 0; i < 6; i++) {
    const random = Math.random();
    const bit = (random * 16) | 0;
    color += bit.toString(16);
  }
  return color;
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    payload.sort((a, b) => b.value - a.value);

    return (
      <div
        style={{
          background: "white",
          padding: "1em",
          width: "400",
        }}
      >
        <Row>
          <span>
            {`${DateTime.fromISO(payload[0].payload.date).toLocaleString()}`} -{" "}
            {`${payload[0].name}`}: {`${payload[0].value}`}
          </span>
        </Row>
        <Row>
          <Col style={{ fontSize: 12 }}>
            {payload.slice(1, 13).map((ligne) => {
              return (
                <span style={{ color: `${ligne.color}` }}>
                  <br />
                  {`${ligne.name}`}:{" "}
                  {`${((100 * ligne.value) / payload[0].value).toFixed(1)}`}%{" "}
                </span>
              );
            })}
          </Col>
          <Col style={{ fontSize: 12 }}>
            {payload.slice(13, 25).map((ligne) => {
              return (
                <span style={{ color: `${ligne.color}` }}>
                  <br />
                  {`${ligne.name}`}:{" "}
                  {`${((100 * ligne.value) / payload[0].value).toFixed(1)}`}%{" "}
                </span>
              );
            })}
          </Col>
          <Col style={{ fontSize: 12 }}>
            {payload.slice(25, 37).map((ligne) => {
              return (
                <span style={{ color: `${ligne.color}` }}>
                  <br />
                  {`${ligne.name}`}:{" "}
                  {`${((100 * ligne.value) / payload[0].value).toFixed(1)}`}%{" "}
                </span>
              );
            })}
          </Col>
        </Row>
      </div>
    );
  }

  return null;
};
const bsIndexChartEUR = (props) => {
  let data = props.userGraph;
  console.log(data);
  const renderLineChart = (
    <ResponsiveContainer width="100%" height={350}>
      <AreaChart
        data={data}
        fontSize={12}
        margin={{
          top: 5,
          right: 0,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          tickFormatter={(date) => DateTime.fromISO(date).toFormat("MM/yyyy")}
        />
        <YAxis
          width={20}
          angle={-50}
          domain={[0, (dataMax) => (dataMax * 1.1).toFixed(1) * 1]}
        />

        <Tooltip
          labelFormatter={(date) =>
            DateTime.fromISO(date).toFormat("dd/MM/yyyy")
          }
          allowEscapeViewBox={{ y: true }}
          content={<CustomTooltip />}
          position={{ x: 0, y: 0 }}
        />

        {data[0]?.bs40.playerList.map((player, i) => {
          return (
            <Area
              type="monotone"
              name={player.displayName}
              stackId="1"
              dataKey={"bs40.playerList[" + i + "].avgPriceEUR"}
              stroke="none"
              fill={randomColor()}
            />
          );
        })}
        <Area
          type="monotone"
          name="BS Index EUR"
          stackId="4"
          dataKey="bs40.roasterValuation5TEUR"
          stroke="blue"
          fill="none"
        />
        <Brush
          tickFormatter={(date) =>
            DateTime.fromISO(date).toFormat("dd/MM/yyyy")
          }
          dataKey="date"
          height={30}
          stroke="#8884d8"
        />
      </AreaChart>
    </ResponsiveContainer>
  );

  return renderLineChart;
};

export default bsIndexChartEUR;
