import React, { useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

import { Form, Button, Container } from "react-bootstrap";
import { AuthContext } from "../Auth/auth";

const SignUp = () => {
  let navigate = useNavigate();
  let location = useLocation();
  let { from } = location.state || { from: { pathname: "/" } };

  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [signupSecret, setSignupSecret] = useState("");

  const onSubmit = (event) => {
    event.preventDefault();
    axios
      .post("/auth/signup", {
        email: userEmail,
        password: password,
        signupSecret: signupSecret,
      })
      .then(({ data }) => {
        console.log(data);
        alert("User created");

        navigate("/login");
      })
      .catch((error) => {
        console.log(error);
        if (error.response?.data?.error == "Mauvais secret") {
          alert("Wrong application secret");
        } else if (error.response?.data?.error == "User already exists") {
          alert("User already exists");
        } else if (error.response?.data?.error == "DB Error") {
          alert("DB Error");
        }
      });
  };

  const validateForm = () => {
    return (
      userEmail.length > 0 &&
      password.length > 0 &&
      password === confirmPassword &&
      signupSecret.length > 0
    );
  };

  return (
    <div>
      <Container>
        <div className="form-wrapper">
          <Form onSubmit={onSubmit}>
            <Form.Group className="mb-3" controlId="formSecret">
              <Form.Label>Secret</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter application secret"
                onChange={(e) => setSignupSecret(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>User</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                onChange={(e) => setUserEmail(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Confirm Password"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Form.Group>

            <Button variant="primary" type="submit" disabled={!validateForm()}>
              Sign Up
            </Button>
          </Form>
        </div>
      </Container>
    </div>
  );
};
export default SignUp;
