import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../Auth/auth";
import { Row, Col, Accordion, Spinner } from "react-bootstrap";

import authHeader from "../../services/auth-header";

import StatsChart from "../Charts/statsChart";
import StatsChartEUR from "../Charts/statsChartEUR";
import BS40ProfitChart from "../Charts/bs40ProfitChart";
import BS40ProfitChartEUR from "../Charts/bs40ProfitChartEUR";
import BSIndexChart from "../Charts/bsIndexChart";
import BSIndexChartEUR from "../Charts/bsIndexChartEUR";
import NbCardChart from "../Charts/nbCardChart";
import DifBSProfitChart from "../Charts/difBSProfitChart";
import DifBSProfitChartEUR from "../Charts/difBSProfitChartEUR";
import StatCard from "../Cards/statCard";

import { FaEthereum } from "react-icons/fa";
import MonthlyProfitChart from "../Charts/monthlyProfitChat";
import DailyActivityChart from "../Charts/dailyActivityChart";
import HistoEmissionChart from "../Charts/histoEmissionChart";

const UserStats = () => {
  const [user, setUser] = useState([]);
  const [userGraph, setUserGraph] = useState([]);

  const [isDataLoading, setIsDataLoading] = useState(false);

  const [monthlyProfits, setMonthlyProfits] = useState([]);
  // const [histoEmission, setHistoEmission] = useState([]);
  const [dailyActivity, setDailyActivity] = useState([]);
  const [avgBuy, setAvgBuy] = useState([]);
  const [avgSell, setAvgSell] = useState([]);

  const { isAuthenticated, checkAuth, isLoading, role, userName } =
    useContext(AuthContext);

  useEffect(() => {
    setIsDataLoading(true);
    axios
      .get("/sorare/getUserStats", { headers: authHeader() })
      .then(({ data }) => {
        console.log(data);
        setUser(data);
        setIsDataLoading(false);
        setUserGraph(data.userGraph);
        setMonthlyProfits(data.monthlyProfits);
        //  setHistoEmission(data.histoEmission);
        setDailyActivity(data.dailyActivities.dailyActivities);
        setAvgBuy(data.dailyActivities.avgBuy);
        setAvgSell(data.dailyActivities.avgSell);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return isLoading ? (
    <Spinner animation="border" />
  ) : (
    <div>
      <Accordion defaultActiveKey={["0", "1", "3", "4", "6"]} alwaysOpen>
        {role === "viewer" ? (
          <Accordion.Item eventKey="6">
            <Accordion.Header>{userName}</Accordion.Header>
            <Accordion.Body>
              {" "}
              <Row>
                {" "}
                <Col lg={3} md={6} style={{ zIndex: 999 }}>
                  <StatCard
                    header="Invested"
                    bodyMain={{
                      value: user?.invested * 1,
                      unit: (
                        <FaEthereum style={{ verticalAlign: "text-top" }} />
                      ),
                    }}
                    bodySec={{ value: "Part: " + user?.part + " %" }}
                    isDataLoading={isDataLoading}
                  />
                </Col>
                <Col lg={3} md={6} style={{ zIndex: 999 }}>
                  <StatCard
                    header="Current Value"
                    bodyMain={{
                      value:
                        (
                          ((user?.roasterValuation5trans * 1 +
                            user?.availableBalance * 1) *
                            user?.part *
                            1) /
                          100
                        ).toFixed(4) * 1,
                      unit: (
                        <FaEthereum style={{ verticalAlign: "text-top" }} />
                      ),
                    }}
                    bodySec={{
                      value: (
                        ((user?.roasterValuation5transEUR * 1 +
                          user?.availableBalanceEUR * 1) *
                          user?.part *
                          1) /
                        100
                      )?.toLocaleString("fr-FR", {
                        style: "currency",
                        currency: "EUR",
                      }),
                    }}
                    isDataLoading={isDataLoading}
                  />
                </Col>
                <Col lg={3} md={6} style={{ zIndex: 999 }}>
                  <StatCard
                    header="PNL"
                    bodyMain={{
                      value:
                        (
                          ((user?.roasterValuation5trans * 1 +
                            user?.availableBalance * 1) *
                            user?.part *
                            1) /
                            100 -
                          user?.invested
                        ).toFixed(4) * 1,
                      unit: (
                        <FaEthereum style={{ verticalAlign: "text-top" }} />
                      ),
                    }}
                    mainColor={true}
                    bodySec={{ value: "" }}
                    isDataLoading={isDataLoading}
                  />
                </Col>
                <Col lg={3} md={6} style={{ zIndex: 999 }}>
                  <StatCard
                    header="Potential Dividends"
                    bodyMain={{
                      value: (
                        ((user?.part * 1) / 100) *
                        user.lockedCash *
                        0.8
                      ).toFixed(4),
                      unit: (
                        <FaEthereum style={{ verticalAlign: "text-top" }} />
                      ),
                    }}
                    bodySec={{ value: "" }}
                    isDataLoading={isDataLoading}
                  />
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        ) : (
          ""
        )}
        <Accordion.Item eventKey="0">
          <Accordion.Header>Moula</Accordion.Header>
          <Accordion.Body>
            {" "}
            <Row>
              {" "}
              <Col lg={3} md={6} style={{ zIndex: 999 }}>
                <StatCard
                  header="Cash"
                  bodyMain={{
                    value: user?.availableBalance,
                    unit: <FaEthereum style={{ verticalAlign: "text-top" }} />,
                  }}
                  bodySec={{
                    value: user?.availableBalanceEUR?.toLocaleString("fr-FR", {
                      style: "currency",
                      currency: "EUR",
                    }),
                  }}
                  footer={
                    " Avl: " +
                    (user?.availableBalance - user?.lockedCash).toFixed(4) +
                    " Lck: " +
                    user?.lockedCash
                  }
                  isDataLoading={isDataLoading}
                />
              </Col>
              <Col lg={3} md={6} style={{ zIndex: 999 }}>
                <StatCard
                  header="PTF Valuation"
                  bodyMain={{
                    value: user?.roasterValuation5trans,
                    unit: <FaEthereum style={{ verticalAlign: "text-top" }} />,
                  }}
                  bodySec={{
                    value: user?.roasterValuation5transEUR?.toLocaleString(
                      "fr-FR",
                      {
                        style: "currency",
                        currency: "EUR",
                      }
                    ),
                  }}
                  footer={"@Floor: " + user?.roasterValuationFloor}
                  isDataLoading={isDataLoading}
                  help={"Sum of owned cards valuation"}
                />
              </Col>
              <Col lg={3} md={6} style={{ zIndex: 999 }}>
                <StatCard
                  header="MTM"
                  bodyMain={{
                    value: (
                      user?.roasterValuation5trans - user?.roasterPurchase
                    )?.toFixed(4),
                    unit: <FaEthereum style={{ verticalAlign: "text-top" }} />,
                  }}
                  mainColor={true}
                  bodySec={{
                    value: (
                      user?.roasterValuation5transEUR - user?.roasterPurchaseEUR
                    )?.toLocaleString("fr-FR", {
                      style: "currency",
                      currency: "EUR",
                    }),
                  }}
                  secColor={true}
                  footer={
                    "+ " +
                    user?.mtmProfit +
                    " (" +
                    user?.nbMTMProfit +
                    ") " +
                    " | - " +
                    user?.mtmLoss * -1 +
                    " (" +
                    user?.nbMTMLoss +
                    ")"
                  }
                  isDataLoading={isDataLoading}
                  help={"Owned Cards : Purchase Price - Valuation"}
                />
              </Col>
              <Col lg={3} md={6} style={{ zIndex: 999 }}>
                <StatCard
                  header="PNL"
                  bodyMain={{
                    value: (
                      user?.roasterValuation5trans * 1 -
                      user?.totalDeposit * 1 +
                      user?.availableBalance * 1
                    ).toFixed(4),
                    unit: <FaEthereum style={{ verticalAlign: "text-top" }} />,
                  }}
                  mainColor={true}
                  secColor={true}
                  bodySec={{
                    value: (
                      user?.roasterValuation5transEUR * 1 -
                      user?.totalDepositEUR * 1 +
                      user?.availableBalanceEUR * 1
                    )?.toLocaleString("fr-FR", {
                      style: "currency",
                      currency: "EUR",
                    }),
                  }}
                  footer={"Monthly var: " + user?.monthlyPNLVar?.[11]?.pnlVar}
                  isDataLoading={isDataLoading}
                  help={"Valuation + Cash - Deposit"}
                />
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Portfolio</Accordion.Header>
          <Accordion.Body>
            {" "}
            <Row>
              <Col lg={3} md={6} style={{ zIndex: 999 }}>
                <StatCard
                  header="Cards"
                  bodyMain={{
                    value:
                      user?.cardCounts?.limited * 1 +
                      user?.cardCounts?.rare * 1,
                  }}
                  bodySec={""}
                  footer={
                    "🟡 " +
                    user?.cardCounts?.limited +
                    " 🔴 " +
                    user?.cardCounts?.rare
                  }
                  isDataLoading={isDataLoading}
                />
              </Col>
              <Col lg={3} md={6} style={{ zIndex: 999 }}>
                <StatCard
                  header="Purchase Price"
                  bodyMain={{
                    value: user?.roasterPurchase,
                    unit: <FaEthereum style={{ verticalAlign: "text-top" }} />,
                  }}
                  bodySec={{
                    value: user?.roasterPurchaseEUR?.toLocaleString("fr-FR", {
                      style: "currency",
                      currency: "EUR",
                    }),
                  }}
                  footer={
                    " 🟡 " +
                    user?.roasterLimitedPurchase +
                    " 🔴 " +
                    user?.roasterRarePurchase
                  }
                  isDataLoading={isDataLoading}
                />
              </Col>
              <Col lg={3} md={6} style={{ zIndex: 999 }}>
                <StatCard
                  header="Rewards"
                  bodyMain={""}
                  bodySec={{
                    value: user?.ethRewards,
                    unit: <FaEthereum style={{ verticalAlign: "text-top" }} />,
                  }}
                  footer={
                    "Cards:" +
                    " 🟡 " +
                    user?.limitedRewards +
                    " 🔴 " +
                    user?.rareRewards
                  }
                  isDataLoading={isDataLoading}
                />
              </Col>
              <Col lg={3} md={6} style={{ zIndex: 999 }}>
                <StatCard
                  header="Players Owned"
                  bodyMain={{ value: user?.nbPlayerOwned }}
                  bodySec={""}
                  footer={
                    " 🟡 " +
                    user?.nbPlayersOwnedLimited +
                    " 🔴 " +
                    user?.nbPlayersOwnedRare
                  }
                  isDataLoading={isDataLoading}
                  help={"Nb Players with at least one card by rarity"}
                />
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2 ">
          {" "}
          <Accordion.Header>Other Stats</Accordion.Header>
          <Accordion.Body>
            {" "}
            <Row>
              <Col lg={3} md={6} style={{ zIndex: 999 }}>
                <StatCard
                  header="Strategies"
                  bodyMain={{
                    value:
                      user?.nbPlayerLimitedStrategies * 1 +
                      user?.nbPlayerRareStrategies * 1,
                  }}
                  bodySec={{ value: "" }}
                  footer={
                    " 🟡 " +
                    user?.nbPlayerLimitedStrategies +
                    " 🔴 " +
                    user?.nbPlayerRareStrategies
                  }
                  isDataLoading={isDataLoading}
                />
              </Col>
              <Col lg={3} md={6} style={{ zIndex: 999 }}>
                <StatCard
                  header="Deposit"
                  bodyMain={{
                    value: user?.totalDeposit,
                    unit: <FaEthereum style={{ verticalAlign: "text-top" }} />,
                  }}
                  bodySec={{
                    value: user?.totalDepositEUR?.toLocaleString("fr-FR", {
                      style: "currency",
                      currency: "EUR",
                    }),
                  }}
                  isDataLoading={isDataLoading}
                />
              </Col>
              <Col lg={3} md={6} style={{ zIndex: 999 }}>
                <StatCard
                  header="Total Purchase"
                  bodyMain={{
                    value:
                      (
                        user?.spentOnAuction * -1 +
                        user?.spentOnSSO * -1
                      ).toFixed(4) * 1,
                    unit: <FaEthereum style={{ verticalAlign: "text-top" }} />,
                  }}
                  bodySec={{
                    value: (
                      user?.spentOnAuctionEUR * -1 +
                      user?.spentOnSSOEUR * -1
                    )?.toLocaleString("fr-FR", {
                      style: "currency",
                      currency: "EUR",
                    }),
                  }}
                  isDataLoading={isDataLoading}
                  footer={
                    "Nb Auctions: " +
                    user?.nbAuction +
                    " | Nb OTC: " +
                    user?.nbSSO
                  }
                />
              </Col>
              <Col lg={3} md={6} style={{ zIndex: 999 }}>
                <StatCard
                  header="Total Sales"
                  bodyMain={{
                    value: user?.earnedOnMarket,
                    unit: <FaEthereum style={{ verticalAlign: "text-top" }} />,
                  }}
                  bodySec={{
                    value: user?.earnedOnMarketEUR?.toLocaleString("fr-FR", {
                      style: "currency",
                      currency: "EUR",
                    }),
                  }}
                  footer={
                    " Lckd: " +
                    user?.totalLockedProfit +
                    " ETH | " +
                    user?.totalLockedProfitEUR?.toLocaleString("fr-FR", {
                      style: "currency",
                      currency: "EUR",
                    })
                  }
                  isDataLoading={isDataLoading}
                />
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3">
          <Accordion.Header>PNL Charts</Accordion.Header>
          <Accordion.Body>
            <Row>
              <Col md={12} lg={6} style={{ margin: 0, padding: 0 }}>
                <StatsChart userGraph={userGraph} />
              </Col>
              <Col md={12} lg={6} style={{ margin: 0, padding: 0 }}>
                <StatsChartEUR userGraph={userGraph} />
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>Market Charts</Accordion.Header>
          <Accordion.Body>
            <Row>
              <Col md={12} lg={6} style={{ margin: 0, padding: 0 }}>
                <BSIndexChart userGraph={userGraph} />
              </Col>

              <Col md={12} lg={6} style={{ margin: 0, padding: 0 }}>
                <BSIndexChartEUR userGraph={userGraph} />
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <HistoEmissionChart />
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item>
          <Accordion.Header>PNL vs BS Charts</Accordion.Header>
          <Accordion.Body>
            <Row>
              <Col md={12} lg={6} style={{ margin: 0, padding: 0 }}>
                <BS40ProfitChart userGraph={userGraph} />
              </Col>
              <Col md={12} lg={6} style={{ margin: 0, padding: 0 }}>
                <BS40ProfitChartEUR userGraph={userGraph} />
              </Col>
            </Row>
            <Row>
              <Col md={12} lg={6} style={{ margin: 0, padding: 0 }}>
                <DifBSProfitChart userGraph={userGraph} />
              </Col>
              <Col md={12} lg={6} style={{ margin: 0, padding: 0 }}>
                <DifBSProfitChartEUR userGraph={userGraph} />
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="5">
          <Accordion.Header>Other Charts</Accordion.Header>
          <Accordion.Body>
            <Row>
              <Col md={12} lg={6} style={{ margin: 0, padding: 0 }}>
                <MonthlyProfitChart monthlyProfits={monthlyProfits} />
              </Col>
              <Col md={12} lg={6} style={{ margin: 0, padding: 0 }}>
                <NbCardChart userGraph={userGraph} />
              </Col>
            </Row>
            <Row>
              <Col md={12} lg={6} style={{ margin: 0, padding: 0 }}>
                <DailyActivityChart
                  dailyActivity={dailyActivity}
                  avgBuy={avgBuy}
                  avgSell={avgSell}
                />
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default UserStats;
