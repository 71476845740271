import React, { useState, useEffect } from "react";
import axios from "axios";
import authHeader from "../../services/auth-header.js";
import { useLocation, useNavigate } from "react-router-dom";

const API_URL = "/auth/";

export const AuthContext = React.createContext({});

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userName, setUserName] = useState([]);

  const [role, setRole] = useState([]);

  useEffect(() => {
    checkAuth();
  }, []);

  const checkAuth = async () => {
    return axios
      .get(API_URL + "checkauth", { headers: authHeader() })
      .then((response) => {
        if (response.data.validUser) {
          setIsAuthenticated(true);
          setUserName(response.data.user);
          setRole(response.data.role);
          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsAuthenticated(false);

        setUserName("");
        setRole("");
        setIsLoading(false);
      });
  };

  const login = async (userEmail, password) => {
    return axios
      .post(API_URL + "login", {
        userEmail,
        password,
      })
      .then((response) => {
        if (response.data.token) {
          localStorage.setItem("botSorareUser", JSON.stringify(response.data));
        }
        if (response.data.role) {
          setRole(response.data.role);
        }
      })
      .then(() => {
        const origin = location.state?.from?.pathname || "/search-player";
        navigate(origin);
      })
      .then(() => {
        setIsAuthenticated(true);
        setUserName(userEmail);
        setIsLoading(false);
      })
      .catch((error) => {
        alert(error);
        setIsAuthenticated(false);
        setUserName("");
        setRole("");
        setIsLoading(false);
      });
  };

  const logout = () => {
    localStorage.removeItem("botSorareUser");
    setIsAuthenticated(false);
    setUserName("");
    setRole("");
    setIsLoading(false);
  };

  return (
    <AuthContext.Provider
      value={{
        userName,
        isLoading,
        isAuthenticated,
        login,
        logout,
        checkAuth,
        role,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
