import {
  Container,
  Button,
  Row,
  Col,
  Accordion,
  Badge,
  DropdownButton,
  Dropdown,
  ButtonGroup,
  Spinner,
} from "react-bootstrap";
import { AuthContext } from "../Auth/auth";
import authHeader from "../../services/auth-header";
import axios from "axios";
import React, { useState, useEffect, useContext } from "react";

import SetAutoBidCoef from "../Actions/setAutobidCoef";
import SetCurrentYearCoef from "../Actions/setYearCoef";
import RefreshCards from "../Actions/refreshCards";
import SorareAccess from "../Forms/SorareAccess";
import TelegramAccess from "../Forms/TelegramAccess";
import FetchDBForm from "../Forms/FetchDBForm";
import UserSellStratForm from "../Forms/UserSellStratForm";
import APIKey from "../Forms/APIKey";
import SetMaxBuyCoefSafety from "../Actions/setMaxBuyCoefSafety";

const Admin = () => {
  const { isAuthenticated, checkAuth, isLoading, role } =
    useContext(AuthContext);

  const [userInfo, setUserInfo] = useState({});
  const [bidCoef, setBidCoef] = useState("");
  const [maxBuyCoef, setMaxBuyCoef] = useState("");
  const [yearCoef, setYearCoef] = useState("");

  const [isPlayersRefreshing, setIsPlayersRefreshing] = useState(false);
  const [isClubsRefreshing, setIsClubsRefreshing] = useState(false);
  const [isLeaguesRefreshing, setIsLeaguesRefreshing] = useState(false);
  const [isBS40Refreshing, setIsBS40Refreshing] = useState(false);
  const [isLeagueEmissionRefreshing, setIsLeagueEmissionRefreshing] =
    useState(false);

  const [isAccountStatRefreshing, setIsAccountStatRefreshing] = useState(false);
  const [isBSProfitsRefreshing, setIsBSProfitsRefreshing] = useState(false);
  const [isSalesRefreshing, setIsSalesRefreshing] = useState(false);
  const [isStrategyPricesRefreshing, setIsStrategyPricesRefreshing] =
    useState(false);

  useEffect(() => {
    axios
      .get("/sorare/getUserInfo", { headers: authHeader() })
      .then(({ data }) => {
        console.log(data);
        setUserInfo(data);
        setBidCoef(data.bidCoef);
        setYearCoef(data.yearCoef);
        setMaxBuyCoef(data.sellStrat?.maxBuyCoef);
      })
      .catch((error) => console.log(error));
  }, []);

  const refreshPlayers = () => {
    setIsPlayersRefreshing(true);
    axios
      .get("/sorare/refreshPlayers", { headers: authHeader() })
      .then(() => {
        setIsPlayersRefreshing(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const refreshClubs = () => {
    setIsClubsRefreshing(true);
    axios
      .get("/sorare/refreshClubs", { headers: authHeader() })
      .then(() => {
        setIsClubsRefreshing(false);
      })
      .catch((error) => {
        alert("Something went wrong");
        console.log(error);
      });
  };

  const resetBS40 = () => {
    setIsBS40Refreshing(true);
    axios
      .get("/sorare/resetBS40", { headers: authHeader() })
      .then(() => {
        setIsBS40Refreshing(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const resetLeagueEmission = () => {
    setIsLeagueEmissionRefreshing(true);
    axios
      .get("/sorare/resetLeagueEmission", { headers: authHeader() })
      .then(() => {
        setIsLeagueEmissionRefreshing(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const resetAccountStats = () => {
    setIsAccountStatRefreshing(true);
    axios
      .get("/sorare/resetAccountStats", { headers: authHeader() })
      .then(() => {
        setIsAccountStatRefreshing(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const sellExcessCards = (rarity) => {
    setIsSalesRefreshing(true);
    axios
      .put(
        "/sorare/sellExcessCard",
        { rarity: rarity },
        { headers: authHeader() }
      )
      .then(() => {
        setIsSalesRefreshing(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const refreshLeaguePrices = (competitionSlug) => {
    setIsLeaguesRefreshing(true);
    axios
      .post(
        "/sorare/refreshLeaguePrices",
        { competitionSlug: competitionSlug },
        { headers: authHeader() }
      )
      .then(() => {
        setIsLeaguesRefreshing(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const refreshUsersPlayersStrategies = () => {
    setIsStrategyPricesRefreshing(true);
    axios
      .get(
        "/sorare/refreshUsersPlayersStrategies",

        { headers: authHeader() }
      )
      .then(() => {
        setIsStrategyPricesRefreshing(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const resetBSProfit = () => {
    setIsBSProfitsRefreshing(true);
    axios
      .get("/sorare/resetBSProfit", { headers: authHeader() })
      .then(() => {
        setIsBSProfitsRefreshing(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return isLoading ? (
    <Spinner animation="border" />
  ) : (
    <div>
      <Row style={{ textAlign: "center" }}>
        <Col>
          <Badge
            style={{ marginBottom: 10, fontSize: 15 }}
            pill
            bg={userInfo.actif ? "success" : "danger"}
          >
            {userInfo.actif ? "Active User" : "Inactive User"}
          </Badge>
        </Col>
      </Row>

      <Accordion defaultActiveKey={["0", "1"]} alwaysOpen>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Edit User Settings</Accordion.Header>
          <Accordion.Body>
            <Row style={{ marginBottom: 5 }}>
              {" "}
              <Col>
                {isSalesRefreshing ? (
                  <Spinner animation="border" />
                ) : (
                  <DropdownButton
                    variant="outline-warning"
                    id="select-strategy"
                    onSelect={sellExcessCards}
                    title={"Sell Excess cards"}
                  >
                    <Dropdown.Item eventKey="limited">
                      Sell Excess limited
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="rare">
                      Sell Excess rare
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="super_rare">
                      Sell Excess super rare
                    </Dropdown.Item>
                  </DropdownButton>
                )}
              </Col>
            </Row>

            <hr />
            <Row style={{ marginBottom: 5 }}>
              <SetAutoBidCoef bidCoef={bidCoef} setBidCoef={setBidCoef} />
            </Row>

            <Row style={{ marginBottom: 5 }}>
              <SetCurrentYearCoef
                yearCoef={yearCoef}
                setYearCoef={setYearCoef}
              />
            </Row>
            <hr />
            <Row style={{ marginBottom: 5 }}>
              <SetMaxBuyCoefSafety
                maxBuyCoef={maxBuyCoef}
                setMaxBuyCoef={setMaxBuyCoef}
              />
            </Row>
            <hr />
            <Row style={{ marginLeft: 0 }}>
              <UserSellStratForm sellStrat={userInfo?.sellStrat} />
            </Row>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header> Refresh User Database</Accordion.Header>
          <Accordion.Body>
            <Container>
              <Row style={{ marginBottom: 5 }}>
                <RefreshCards subTitle="Cards" />
              </Row>

              <Row style={{ marginBottom: 5 }}>
                {" "}
                <Col>
                  {isStrategyPricesRefreshing ? (
                    <Spinner animation="border" />
                  ) : (
                    <Button
                      variant="outline-warning"
                      onClick={refreshUsersPlayersStrategies}
                    >
                      Refresh Strategy list prices
                    </Button>
                  )}
                </Col>
              </Row>
              <Row style={{ marginBottom: 5 }}>
                {" "}
                <Col>
                  {isAccountStatRefreshing ? (
                    <Spinner animation="border" />
                  ) : (
                    <Button
                      variant="outline-danger"
                      onClick={resetAccountStats}
                    >
                      Reset Account Stats
                    </Button>
                  )}
                </Col>
              </Row>
              <Row style={{ marginBottom: 5 }}>
                {" "}
                <Col>
                  {isBSProfitsRefreshing ? (
                    <Spinner animation="border" />
                  ) : (
                    <Button variant="outline-danger" onClick={resetBSProfit}>
                      Reset BS Profits
                    </Button>
                  )}
                </Col>
              </Row>
            </Container>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header> Refresh Database</Accordion.Header>
          <Accordion.Body>
            <Container>
              <Row style={{ marginBottom: 5 }}>
                {" "}
                <Col>
                  {isLeaguesRefreshing ? (
                    <Spinner animation="border" />
                  ) : (
                    <DropdownButton
                      variant="outline-warning"
                      id="select-strategy"
                      onSelect={refreshLeaguePrices}
                      title={"Refresh League prices"}
                    >
                      <Dropdown.Item eventKey="ligue-1-fr">
                        Ligue 1
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="serie-a-it">
                        Serie A
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="bundesliga-de">
                        BundesLiga
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="premier-league-gb-eng">
                        Premier League
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="laliga-santander">
                        Liga
                      </Dropdown.Item>
                    </DropdownButton>
                  )}
                </Col>
              </Row>
              <Row style={{ marginBottom: 5 }}>
                <Col>
                  {isPlayersRefreshing ? (
                    <Spinner animation="border" />
                  ) : (
                    <Button variant="outline-danger" onClick={refreshPlayers}>
                      Refresh Players database
                    </Button>
                  )}
                </Col>
              </Row>
              <Row style={{ marginBottom: 5 }}>
                {" "}
                <Col>
                  {isClubsRefreshing ? (
                    <Spinner animation="border" />
                  ) : (
                    <Button variant="outline-danger" onClick={refreshClubs}>
                      Refresh Clubs database
                    </Button>
                  )}
                </Col>
              </Row>

              <Row style={{ marginBottom: 5 }}>
                {" "}
                <Col>
                  {isBS40Refreshing ? (
                    <Spinner animation="border" />
                  ) : (
                    <Button variant="outline-danger" onClick={resetBS40}>
                      Reset BS40
                    </Button>
                  )}
                </Col>
              </Row>
              <Row style={{ marginBottom: 5 }}>
                {" "}
                <Col>
                  {isLeagueEmissionRefreshing ? (
                    <Spinner animation="border" />
                  ) : (
                    <Button
                      variant="outline-danger"
                      onClick={resetLeagueEmission}
                    >
                      Reset League Emission
                    </Button>
                  )}
                </Col>
              </Row>
            </Container>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3">
          <Accordion.Header>Edit Sorare Access</Accordion.Header>
          <Accordion.Body>
            <SorareAccess />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>Edit Telegram Access</Accordion.Header>
          <Accordion.Body>
            <TelegramAccess />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>Edit API Key</Accordion.Header>
          <Accordion.Body>
            <APIKey />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default Admin;
