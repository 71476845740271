import React from "react";
import { Container, Row, Col, Nav } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "./App.css";

import { Routes, Route, Navigate } from "react-router-dom";

import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import ViewerRoute from "./components/ProtectedRoute/ViewerRoute";
import ScoutRoute from "./components/ProtectedRoute/ScoutRoute";

import SearchPlayer from "./components/views/search-player.component";
import StrategyList from "./components/views/strategy-list.component";
import PlayerInfo from "./components/views/player.component";
import UserCards from "./components/views/user-cards.component";
import Login from "./components/views/login.component";
import Home from "./components/views/home.component";
import Admin from "./components/views/admin.component";
import UserHist from "./components/views/user-history.component";
import UserStats from "./components/views/user-stats.component";
import SignUp from "./components/views/signup.component";

import Navigation from "./components/views/navigation.component";

import AuthProvider from "./components/Auth/auth";

import CompetitionPlayerList from "./components/views/competition-player-list.component";

const App = () => {
  return (
    <div className="App">
      <AuthProvider>
        <Navigation />

        <Container>
          <Row>
            <Col md={12}>
              <div className="wrapper">
                <Routes>
                  <Route index element={<Home />} />
                  <Route path="/home" element={<Home />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/signup" element={<SignUp />} />
                  <Route
                    path="/user-stats"
                    element={
                      <ViewerRoute>
                        <UserStats />
                      </ViewerRoute>
                    }
                  />

                  <Route
                    path="/user-history"
                    element={
                      <ProtectedRoute>
                        <UserHist />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/user-cards/"
                    element={
                      <ProtectedRoute>
                        <UserCards />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/search-player/"
                    element={
                      <ScoutRoute>
                        <SearchPlayer />
                      </ScoutRoute>
                    }
                  />
                  <Route
                    path="/player/:id/:rarity"
                    element={
                      <ScoutRoute>
                        <PlayerInfo />
                      </ScoutRoute>
                    }
                  />

                  <Route
                    path="/competition-list"
                    element={
                      <ScoutRoute>
                        <CompetitionPlayerList />
                      </ScoutRoute>
                    }
                  />
                  <Route
                    path="/strategy-list"
                    element={
                      <ProtectedRoute>
                        <StrategyList />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin"
                    element={
                      <ProtectedRoute>
                        <Admin />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="*" element={<Navigate to="/home" replace />} />
                </Routes>
              </div>
            </Col>
          </Row>
        </Container>
      </AuthProvider>
    </div>
  );
};

export default App;
