import React from "react";

import { DateTime } from "luxon";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
  Brush,
  ResponsiveContainer,
} from "recharts";

const MonthlyProfitChart = (props) => {
  let data = props.monthlyProfits;
  console.log(data);
  return (
    <ResponsiveContainer width="100%" height={350}>
      <BarChart
        fontSize={12}
        margin={{
          top: 5,
          right: 20,
          left: 20,
          bottom: 5,
        }}
        data={data}
      >
        <Bar yAxisId="left" dataKey="totalMonthlyProfit" fill="#8884d8" />
        <Bar yAxisId="right" dataKey="totalMonthlyProfitEUR" fill="#82ca9d" />
        <Tooltip />
        <XAxis dataKey="month" />
        <YAxis
          width={20}
          angle={-50}
          yAxisId="left"
          orientation="left"
          stroke="#8884d8"
        />
        <YAxis
          width={20}
          angle={-50}
          yAxisId="right"
          orientation="right"
          stroke="#82ca9d"
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default MonthlyProfitChart;
