import { Col } from "react-bootstrap";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import authHeader from "../../services/auth-header";
import axios from "axios";
import React, { useState } from "react";

const SetAutoBidCoef = (props) => {
  const { bidCoef, setBidCoef, setReload, reload } = { ...props };

  const setCoef = (coef) => {
    setBidCoef(coef);
    axios
      .put("/sorare/setAutoBidCoef", { coef: coef }, { headers: authHeader() })
      .then(() => {
        if (setReload) {
          setReload(reload + 1);
        }
      })
      .catch((error) => {
        alert("Something went wrong");
        console.log(error);
      });
  };
  return (
    <Col>
      <DropdownButton
        variant="outline-warning"
        id="select-strategy"
        onSelect={setCoef}
        title={"AutoBid Coefficient:" + bidCoef}
      >
        <Dropdown.Item eventKey="0.6">- 40 %</Dropdown.Item>
        <Dropdown.Item eventKey="0.65">- 35 %</Dropdown.Item>
        <Dropdown.Item eventKey="0.7">- 30 %</Dropdown.Item>
        <Dropdown.Item eventKey="0.75">- 25 %</Dropdown.Item>
        <Dropdown.Item eventKey="0.8">- 20 %</Dropdown.Item>
        <Dropdown.Item eventKey="0.85">- 15 %</Dropdown.Item>
        <Dropdown.Item eventKey="0.9">- 10 %</Dropdown.Item>
        <Dropdown.Item eventKey="0.95">- 5 %</Dropdown.Item>
        <Dropdown.Item eventKey="1">Normal</Dropdown.Item>
        <Dropdown.Item eventKey="1.05">+ 5 %</Dropdown.Item>
        <Dropdown.Item eventKey="1.1">+ 10 %</Dropdown.Item>
        <Dropdown.Item eventKey="1.15">+ 15 %</Dropdown.Item>
        <Dropdown.Item eventKey="1.2">+ 20 %</Dropdown.Item>
      </DropdownButton>
    </Col>
  );
};
export default SetAutoBidCoef;
