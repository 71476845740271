import React from "react";
import { DateTime } from "luxon";

import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  AreaChart,
  Area,
  Brush,
  ResponsiveContainer,
} from "recharts";

const DifBSProfitChartEUR = (props) => {
  let data = props.userGraph;
  console.log(data);
  const renderLineChart = (
    <ResponsiveContainer width="100%" height={350}>
      <AreaChart
        data={data}
        fontSize={12}
        margin={{
          top: 5,
          right: 0,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          tickFormatter={(date) => DateTime.fromISO(date).toFormat("MM/yyyy")}
        />
        <YAxis width={20} angle={-50} />

        <Tooltip
          labelFormatter={(date) =>
            DateTime.fromISO(date).toFormat("dd/MM/yyyy")
          }
        />

        <Area
          type="monotone"
          name="Dif PTF Profit EUR - BS Profit EUR"
          stackId="1"
          dataKey="difRoasterBSEUR"
          stroke="blue"
          fill="none"
        />

        <Brush
          dataKey="date"
          height={30}
          stroke="#8884d8"
          tickFormatter={(date) =>
            DateTime.fromISO(date).toFormat("dd/MM/yyyy")
          }
        />
      </AreaChart>
    </ResponsiveContainer>
  );

  return renderLineChart;
};

export default DifBSProfitChartEUR;
