import React from "react";
import { DateTime } from "luxon";

import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  AreaChart,
  Area,
  Brush,
  ResponsiveContainer,
} from "recharts";

const formatXAxis = (tickFormat) => {
  return DateTime.fromMillis(tickFormat).format("DD/MM/YYYY");
};

const StatsChartEUR = (props) => {
  let data = props.userGraph;
  console.log(data);
  const renderLineChart = (
    <ResponsiveContainer width="100%" height={350}>
      <AreaChart
        data={data}
        fontSize={12}
        margin={{
          top: 5,
          right: 0,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          tickFormatter={(date) => DateTime.fromISO(date).toFormat("MM/yyyy")}
        />
        <YAxis
          width={20}
          angle={-50}
          domain={[0, (dataMax) => (dataMax * 1.1).toFixed(1) * 1]}
        />

        <Tooltip
          labelFormatter={(date) =>
            DateTime.fromISO(date).toFormat("dd/MM/yyyy")
          }
        />
        <Area
          type="monotone"
          name="Wallet total EUR"
          stackId="5"
          dataKey="walletEUR"
          stroke="red"
          fill="none"
        />
        <Area
          type="monotone"
          name="Portfolio Purchase Price EUR"
          stackId="4"
          dataKey="roasterPurchasePriceEUR"
          stroke="blue"
          fill="none"
        />
        <Area
          type="monotone"
          name="Portfolio Valuation EUR"
          stackId="1"
          dataKey="roasterValuation5TEUR"
          stroke="#82ca9d"
          fill="#82ca9d"
        />
        <Area
          type="monotone"
          stackId="1"
          name="Cash EUR"
          dataKey="availableBalanceEUR"
          stroke="#8884d8"
          fill="#8884d8"
        />
        <Area
          type="monotone"
          stackId="2"
          name="Deposit EUR"
          dataKey="totalDepositEUR"
          stroke="black"
          fill="none"
        />
        <Area
          type="monotone"
          stackId="3"
          name="Locked profit EUR"
          dataKey="totalProfitEUR"
          stroke="purple"
          fill="none"
        />
        <Brush
          dataKey="date"
          height={30}
          stroke="#8884d8"
          tickFormatter={(date) =>
            DateTime.fromISO(date).toFormat("dd/MM/yyyy")
          }
        />
      </AreaChart>
    </ResponsiveContainer>
  );

  return renderLineChart;
};

export default StatsChartEUR;
