import { Col } from "react-bootstrap";
import { DropdownButton, Dropdown } from "react-bootstrap";
import authHeader from "../../services/auth-header";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const SetCurrentYearCoef = (props) => {
  const { yearCoef, setYearCoef } = { ...props };

  const setCoef = (coef) => {
    setYearCoef(coef);
    axios
      .put("/sorare/setYearCoef", { coef: coef }, { headers: authHeader() })
      .then(() => {
        alert("Current season coef set");
      })
      .catch((error) => {
        alert("Something went wrong");
        console.log(error);
      });
  };
  return (
    <Col>
      <DropdownButton
        variant="outline-warning"
        id="select-strategy"
        onSelect={setCoef}
        title={"Year Coefficient:" + yearCoef}
      >
        <Dropdown.Item eventKey="1">Normal</Dropdown.Item>
        <Dropdown.Item eventKey="1.05">+ 5 %</Dropdown.Item>
        <Dropdown.Item eventKey="1.1">+ 10 %</Dropdown.Item>
        <Dropdown.Item eventKey="1.15">+ 15 %</Dropdown.Item>
        <Dropdown.Item eventKey="1.2">+ 20 %</Dropdown.Item>
      </DropdownButton>
    </Col>
  );
};
export default SetCurrentYearCoef;
