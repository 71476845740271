import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Button, Modal, Form } from "react-bootstrap";
import axios from "axios";

import authHeader from "../../services/auth-header.js";

const EditStrategyPlayerComp = (props) => {
  //Edit strategy
  const currentStrategy = props.currentStrategy;
  const setCurrentStrategy = props.setCurrentStrategy;

  const showStrategy = props.showStrategy;
  const setShowStrategy = props.setShowStrategy;

  const [safetyBid, setSafetyBid] = useState("default");
  const [manualBid, setManualBid] = useState("default");
  const [targetSell, setTargetSell] = useState("default");
  const [budget, setBudget] = useState("default");
  const [buyStrategy, setBuyStrategy] = useState("default");
  const [sellStrategy, setSellStrategy] = useState("default");
  const [sellAtPerc, setSellAtPerc] = useState("default");

  const handleCloseStrategy = () => {
    setBuyStrategy("default");
    setSellStrategy("default");
    setBudget("default");
    setManualBid("default");
    setSafetyBid("default");
    setSellAtPerc("default");
    setTargetSell("default");
    setShowStrategy(false);
  };

  const handleSaveStrategy = (event) => {
    event.preventDefault();
    setShowStrategy(false);

    let newStrat = currentStrategy;

    if (buyStrategy !== "default") {
      newStrat.buyStrategy = buyStrategy;
      newStrat.published = "Pending";
    }
    if (sellStrategy !== "default") {
      newStrat.sellStrategy = sellStrategy;
      newStrat.published = "Pending";
    }
    if (budget !== "default") {
      newStrat.budget = budget;
      newStrat.published = "Pending";
    }
    if (safetyBid !== "default") {
      newStrat.safetyBid = safetyBid;
      newStrat.published = "Pending";
    }
    if (manualBid !== "default") {
      newStrat.manualBid = manualBid;
      newStrat.published = "Pending";
    }
    if (targetSell !== "default") {
      newStrat.targetSell = targetSell;
      newStrat.published = "Pending";
    }
    if (sellAtPerc !== "default") {
      newStrat.sellAtPerc = sellAtPerc;
      newStrat.published = "Pending";
    }

    setBuyStrategy("default");
    setSellStrategy("default");
    setBudget("default");
    setManualBid("default");
    setSafetyBid("default");
    setSellAtPerc("default");
    setTargetSell("default");

    axios
      .post(
        "/sorare/editPlayerStrategy/",
        {
          playerId: currentStrategy.playerId,
          rarity: currentStrategy.rarity,
          buyStrategy: newStrat.buyStrategy,
          sellStrategy: newStrat.sellStrategy,
          safetyBid: newStrat.safetyBid,
          manualBid: newStrat.manualBid,
          budget: newStrat.budget,
          sellAtPerc: newStrat.sellAtPerc,
          targetSell: newStrat.targetSell,
        },
        { headers: authHeader() }
      )
      .then(({ data }) => {
        console.log(data);
        if (data.updated) {
          console.log("published");
          newStrat.published = "Published";
          setCurrentStrategy(newStrat);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  console.log(currentStrategy);
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={showStrategy}
      onHide={handleCloseStrategy}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {currentStrategy?.displayName} - {currentStrategy?.rarity}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSaveStrategy}>
          <Form.Group className="mb-3">
            <Form.Label>Buy Strategy</Form.Label>
            <Form.Select
              defaultValue={
                currentStrategy?.buyStrategy
                  ? currentStrategy?.buyStrategy
                  : "default"
              }
              onChange={(e) => setBuyStrategy(e.target.value)}
            >
              <option value="default"></option>
              <option value="autoBid">Buy @ Auto Bid</option>
              <option value="autoBidMM">Buy @ Auto Bid MM </option>
              <option value="manualBid">Buy @ Manual Bid</option>
              <option value="none"> Do not buy </option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasic">
            <Form.Label> Budget </Form.Label>
            <Form.Control
              type="number"
              step="0.0001"
              min="0"
              placeholder="Set Budget"
              defaultValue={currentStrategy?.budget}
              onChange={(e) => setBudget(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasic">
            <Form.Label> Safety Bid</Form.Label>
            <Form.Control
              type="number"
              step="0.0001"
              min="0"
              placeholder="Set Safety Bid"
              defaultValue={currentStrategy?.safetyBid}
              onChange={(e) => setSafetyBid(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasic">
            <Form.Label> Manual Bid</Form.Label>
            <Form.Control
              type="number"
              step="0.0001"
              min="0"
              placeholder="Set Manual Bid "
              defaultValue={currentStrategy?.manualBid}
              onChange={(e) => setManualBid(e.target.value)}
            />
          </Form.Group>
          <hr />
          <Form.Group className="mb-3" controlId="formBasic">
            <Form.Label>Sell Strategy</Form.Label>
            <Form.Select
              defaultValue={
                currentStrategy?.sellStrategy
                  ? currentStrategy?.sellStrategy
                  : "default"
              }
              onChange={(e) => setSellStrategy(e.target.value)}
            >
              <option value="default"></option>
              <option value="classicHodl">MT Hodl</option>
              <option value="strongHodl">LT Hodl</option>
              <option value="sellAllAtPerc">Sell All @ Profit%</option>
              <option value="sell1AtPerc">Sell 1 @ Profit%</option>
              <option value="sellAllAtTarget">Sell All @ Target</option>
              <option value="sell1AtTarget">Sell 1 @ Target</option>
              <option value="none"> None</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasic">
            <Form.Label>Sell At Percentage</Form.Label>
            <Form.Select
              defaultValue={
                currentStrategy?.sellAtPerc
                  ? currentStrategy?.sellAtPerc
                  : "default"
              }
              onChange={(e) => setSellAtPerc(e.target.value)}
            >
              <option value="default"></option>
              <option value="1">0%</option>
              <option value="1.1">10%</option>
              <option value="1.2">20%</option>
              <option value="1.3">30%</option>
              <option value="1.4"> 40%</option>
              <option value="1.75"> 75%</option>
              <option value="2"> 100%</option>
              <option value="3"> 200%</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasic">
            <Form.Label> Target Sell</Form.Label>
            <Form.Control
              type="number"
              step="0.0001"
              min="0"
              placeholder="Set Target Sell"
              defaultValue={currentStrategy?.targetSell}
              onChange={(e) => setTargetSell(e.target.value)}
            />{" "}
          </Form.Group>
          <Button
            variant="outline-success"
            type="submit"
            //disabled={!validateForm()}
          >
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditStrategyPlayerComp;
