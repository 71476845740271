import React, { useState } from "react";

import { DateTime } from "luxon";
import {
  ComposedChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
  Brush,
  Dot,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts";
import { FaEthereum } from "react-icons/fa";
import { Col, Row, Container } from "react-bootstrap";
import Select from "react-select";
const RenderDot = ({ cx, cy, color }) => {
  return <Dot cx={cx} cy={cy} fill={color} r={2} />;
};

const CustomTooltip = ({ active, payload }) => {
  if (!active) return null;

  if (payload && payload[0])
    return (
      <div
        style={{
          background: "white",
          margin: "2px",
          width: "200px",
        }}
      >
        <Row>
          <span>
            {DateTime.fromMillis(payload[0].payload.day).toLocaleString(
              DateTime.DATETIME_SHORT
            )}
          </span>

          <br />
          <span>
            <span style={{ color: payload[0].color, fontSize: 16 }}>
              {payload[0].name} :{" "}
            </span>
            <span style={{ color: payload[0].color, fontSize: 12 }}>
              <FaEthereum style={{ verticalAlign: "text-top" }} />{" "}
            </span>
            <span style={{ color: payload[0].color, fontSize: 16 }}>
              {payload[0].value}{" "}
            </span>
          </span>
        </Row>
        <Row>
          <Col
            md={12}
            style={{
              display: "flex",
            }}
            className="justify-content-center"
          >
            {payload[0]?.payload?.pictureUrl ? (
              <img
                src={payload[0].payload.pictureUrl}
                alt={payload[0].payload.pictureUrl}
                style={{
                  margin: "2px",
                  border: "0px",
                  width: "200px",
                  zIndex: 800,
                }}
              />
            ) : (
              ""
            )}
          </Col>
        </Row>
      </div>
    );
  else return null;
};

const PriceHistoryChart = (props) => {
  let data = props.priceHistory;

  let player = props.player;
  console.log(data);
  const [selectedReference, setSelectedReference] = useState([
    { data: "avg5T", label: "Avg 5 Trans", color: "red" },
    { data: "autoBid", label: "Auto Bid", color: "blue" },
    { data: "autoBidMM", label: "Auto Bid MM", color: "purple" },
    { data: "floorPrice", label: "Floor Price", color: "green" },
  ]);

  const referenceOptions = [
    { data: "avg5T", label: "Avg 5 Trans", color: "red" },
    { data: "autoBid", label: "Auto Bid", color: "blue" },
    { data: "autoBidMM", label: "Auto Bid MM", color: "purple" },
    { data: "floorPrice", label: "Floor", color: "green" },
  ];
  const onchangeSelectReference = (items) => {
    setSelectedReference(items);
  };

  const [selectedCharts, setSelectedCharts] = useState([
    { data: "predictedPrice", label: "Predicted Price", color: "red" },
    { data: "theoPrice", label: "Theo Price", color: "blue" },
  ]);

  const chartsOptions = [
    { data: "predictedPrice", label: "Predicted Price", color: "red" },
    { data: "theoPrice", label: "Theo Price", color: "blue" },
  ];
  const onchangeSelectChart = (items) => {
    setSelectedCharts(items);
  };

  const renderLineChart = (
    <Container
      style={{
        marginLeft: 0,
        marginRight: 0,
        paddingRight: 0,
        paddingLeft: 0,
      }}
    >
      <Row style={{ marginLeft: 10 }}>
        <Col lg={6}>
          Estimated Prices:{" "}
          <Select
            options={chartsOptions}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            defaultValue={[chartsOptions[0], chartsOptions[1]]}
            onChange={onchangeSelectChart}
            getOptionValue={(option) => option.data}
            getOptionLabel={(option) => option.label}
            styles={{ menu: (styles) => ({ ...styles, zIndex: 999 }) }}
          />{" "}
        </Col>
        <Col lg={6}>
          Reference Lines{" "}
          <Select
            options={referenceOptions}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            defaultValue={[
              referenceOptions[0],
              referenceOptions[1],
              referenceOptions[2],
              referenceOptions[3],
            ]}
            onChange={onchangeSelectReference}
            getOptionValue={(option) => option.data}
            getOptionLabel={(option) => option.label}
            styles={{ menu: (styles) => ({ ...styles, zIndex: 999 }) }}
          />{" "}
        </Col>
      </Row>

      <ResponsiveContainer width="100%" height={400}>
        <ComposedChart
          fontSize={12}
          paddingLeft={0}
          margin={{
            top: 5,
            right: 5,
            left: 15,
            bottom: 5,
          }}
          data={data}
        >
          <XAxis
            dataKey="day"
            domain={["dataMin", "dataMax"]}
            name="Time"
            type="number"
            tickFormatter={(date) =>
              DateTime.fromMillis(date).toFormat("MM/yyyy")
            }
          />
          <Tooltip
            labelFormatter={(date) =>
              DateTime.fromMillis(date).toFormat("dd/MM/yyyy")
            }
            content={CustomTooltip}
          />
          <CartesianGrid strokeDasharray="3 3" />
          <YAxis width={10} angle={-50} />
          <Tooltip cursor={{ strokeDasharray: "3 3" }} />
          <Scatter name="Auction" dataKey="auction" fill="#e0d09d" />
          <Scatter
            name="OTC Sales"
            dataKey="single_sale_offer"
            fill="#95d1c9"
          />
          <Scatter
            name="OTC Direct"
            dataKey="single_buy_offer"
            fill="#b2e9b0"
          />

          {selectedCharts.map((chart) => {
            return (
              <Scatter
                name={chart.label}
                dataKey={chart.data}
                shape={<RenderDot color={chart.color} />}
                fill={chart.color}
              />
            );
          })}
          <Scatter name="Sold" dataKey="sold" fill="red" shape="cross" />
          <Scatter name="Bought" dataKey="bought" fill="green" shape="cross" />
          {selectedReference.map((chart) => {
            return (
              <ReferenceLine
                y={player?.pricesLevel?.[0]?.[chart.data]}
                label={chart.label}
                stroke={chart.color}
                strokeDasharray="3 3"
              />
            );
          })}

          <Brush
            dataKey="date"
            height={30}
            stroke="#8884d8"
            tickFormatter={(date) =>
              DateTime.fromISO(date).toFormat("dd/MM/yyyy")
            }
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Container>
  );

  return renderLineChart;
};

export default PriceHistoryChart;
